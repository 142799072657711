import React from 'react'
import { observer } from 'mobx-react'
import GroupedValuesPane from '../components/GroupedValuesPane'
import ApiClientFactory from '../api/ApiClientFactory'
import { getRelUrl } from '../common/Util'
import AppStateStore from '../stores/AppStateStore'
import { CommonContext } from '../CommonContext'
import {StatementsContainer} from "../styles/Container.styles";

const PolicyView = observer(class PolicyView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Policy'
  }

  private getGroupedValues = async (url: string) => {
    return ApiClientFactory.getInstance()
      .get(url)
  }

  render () {
    return <CommonContext.Consumer>
      {data => <StatementsContainer>
        <GroupedValuesPane adapter={this.getGroupedValues(getRelUrl(data.commonData.links, data.commonData.policy.rel))}/>
      </StatementsContainer>}
    </CommonContext.Consumer>
  }
});

export default PolicyView
