import {styled} from '@mui/system';

//TODO check some styles that might be unnecessary (like padding: 0 24px for home container)
export const Container = styled('div')`
  max-width: 600px;
  padding: 0 24px;
  margin: auto;
`;

export const StatementsContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('laptop')} {
    padding: 0 40px;
  }

  ${props => props.theme.breakpoints.up('widescreen')} {
    margin: 0;
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    padding-top: 20px;
  }
`;

export const ProfileContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('laptop')} {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    margin-top: 50px;
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
  }

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 0;
  }
`;

export const PaymentStepperContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('laptop')} {
    margin-top: 50px;
    max-width: 700px;
    padding: 20px;

    border: solid 1px #999 !important;
    border-radius: 12px !important;
    box-shadow: 2px 2px 2px 0 #ddd !important;
  }
`;

export const HomeContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('laptop')} {
    max-width: 950px;
    padding: 0 40px;
  }

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 0 24px;
  }
`;

export const PaymentActionsContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('laptop')} {
    max-width: 700px;
    padding: 0 40px;
  }

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 0 24px;
  }
`;

export const StatementsFilter = styled('div')`
  ${props => props.theme.breakpoints.up('widescreen')} {
    flex: .4;
    margin-right: 80px;
  }
`;

export const StatementsFilterHeader = styled('div')`
  text-align: center;

  ${props => props.theme.breakpoints.up('widescreen')} {
    text-align: left !important;
    font-weight: bold;
  }
`