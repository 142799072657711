import {styled} from '@mui/system';

export const ActionCardHeader = styled('div')<{noMargin: boolean}>`
  margin-bottom: ${props => props.noMargin? '0;' : '12px;'}
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActionCardHeaderText = styled('b')`
  flex: 1;
`;

export const ActionCardRowContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const ActionCardRowValue = styled('div')`
  flex: 1;
`;

export const ActionCardRowValueDescription = styled('div')`
  font-size: 0.9rem;

  & .MuiTypography-body1 {
    font-size: 0.9rem;
  }
`;

export const ActionCardRowValueSublabel = styled('div')`
  font-size: 0.9rem;
`;

export const ActionCardRowValueDetail = styled('div')`
  width: 50%;
  text-align: center;
  padding-left: 20px;
`;

export const ActionCardRowValueImage = styled('img')`
  max-height: 81px;
  max-width: 100%;
`;

export const ActionCardRowValueDetailLabel = styled('div')`
  font-weight: 600;
`;

export const ActionCardRowValueDetailValue = styled('div')`
  font-weight: 600;
  color: #c00;
  ${props => props.theme.breakpoints.up('laptop')}{
    font-size: 1.2rem;
  }
`;