import React, { SyntheticEvent } from 'react'
import { formatCurrency, formatNumber, transformIf } from '../common/Util'
import { InteractionContext } from '../InteractionContext'
import { observer } from 'mobx-react'
import Payment, { PaymentOption } from '../models/Payment'
import { computed, observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import moment from 'moment'
import { Button, Input, InputLabel, Theme } from '@mui/material'
import { withTheme } from '@mui/styles'
import _ from 'lodash'
import Loader from '../components/Loader'
import { StepperDescriptor } from '../components/stepper/models/StepperDescriptor'
import DynamicStepper from '../components/stepper/DynamicStepper'
import AppStateStore from '../stores/AppStateStore'
import DynamicIcon from '../components/DynamicIcon'
import { RootRouteContext } from '../RootRouteContext'
import queryString from 'query-string'
import { Alert, AlertTitle } from '@mui/lab'
import UnauthorizedError from '../models/UnauthorizedError'
import EventBus, { EventBusContext } from '../common/EventBus'
import {
  BalanceDue,
  BalanceHeader, DesktopPaymentHeader, DesktopPaymentHeaderBalance, DesktopPaymentHeaderTitle, PaymentButton,
  PaymentFields,
  PaymentFieldsWrapper, PaymentFormContainer,
  PaymentFormContent,
  PaymentOptionButton
} from "../styles/Payment.styles";
import {PaymentStepperContainer} from "../styles/Container.styles";
import {StyledDatePicker} from "../components/StyledDatePicker";

const PaymentView = observer(class PaymentView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Payment'
  }

  render () {
    const rel = transformIf(queryString.parse(window.location.search).rel, t => String(t))
    return <EventBusContext.Consumer>
      {eventBusContext => <InteractionContext.Consumer>
      {interactionContext => transformIf(interactionContext.interaction.getQuickActionLinkByRel(rel ?? 'payment'), link => <PaymentFormWithTheme url={link.href} eventBus={eventBusContext.eventBus}/>)}
    </InteractionContext.Consumer>}
    </EventBusContext.Consumer>
  }
});

type PaymentFormProps = {
  url: string
  theme: Theme
  eventBus: EventBus
}

const PaymentForm = observer(class PaymentForm extends React.Component<PaymentFormProps> {
  private payment?: Payment;
  private error?: string;
  private selectedDate = moment();
  private amount: string = '0';
  private submitting = false;
  private formError?: string;
  private stepperDescriptor?: StepperDescriptor;
  private selectedPaymentOptionId?: string;

  constructor(props: PaymentFormProps) {
    super(props);

    makeObservable<PaymentForm, "payment" | "error" | "selectedDate" | "amount" | "submitting" | "formError" | "stepperDescriptor" | "selectedPaymentOptionId" | "paymentOption" | "paymentOptions" | "selectedPaymentOption">(this, {
      payment: observable,
      error: observable,
      selectedDate: observable,
      amount: observable,
      submitting: observable,
      formError: observable,
      stepperDescriptor: observable,
      selectedPaymentOptionId: observable,
      paymentOption: computed,
      paymentOptions: computed,
      selectedPaymentOption: computed
    });
  }

  private get paymentOption(): PaymentOption | undefined {
    return this.payment
      ? _.find(this.payment.paymentOptions, opt => opt.name === 'One-Time Payment')
      : undefined
  }

  private get paymentOptions(): PaymentOption[] | undefined {
    return this.payment?.paymentOptions.filter(opt => opt.name !== 'One-Time Payment')
  }

  private get selectedPaymentOption(): PaymentOption | undefined {
    return _.find(this.payment?.paymentOptions, p => p.id === this.selectedPaymentOptionId)
  }

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.payment && !this.submitting && this.selectedDate) {
      this.submitting = true
      this.formError = undefined

      const data = Object.assign({}, this.payment.postback.postbackData)

      data.id = this.paymentOption?.id ?? this.selectedPaymentOptionId!
      data.amount = this.paymentOption ? this.amount : this.selectedPaymentOption?.terms.defaultAmount
      data.paymentDate = this.selectedDate.format('YYYY-MM-DDTHH:mm:ss')

      if (this.paymentOption) {
        if (Number(this.amount) < Number(this.paymentOption!.terms.minAmount) || Number(this.amount) > Number(this.paymentOption!.terms.maxAmount)) {
          this.formError = `Payment amount must be between ${formatCurrency(this.paymentOption!.terms.minAmount)} and ${formatCurrency(this.paymentOption!.terms.maxAmount)}`
          this.submitting = false
          return
        }
      }

      ApiClientFactory.getInstance()
        .post(this.payment.postback.postbackUrl, data)
        .then(response => {
          this.stepperDescriptor = response.data
        })
        .catch(error => {
          this.formError = String(error)
        })
        .then(() => this.submitting = false)
    }
  }

  private onDateChange = (date: moment.Moment) => {
    this.selectedDate = date
  }

  componentDidMount (): void {
    this.props.eventBus.on('authenticated', this.onAuthenticated)
    this.fetchPaymentOptions()
  }

  private fetchPaymentOptions = () => {
    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.payment = new Payment().init(response.data)
        this.selectedDate = this.payment.defaultPaymentDate.clone()

        const opt = _.find(this.payment.paymentOptions, opt => opt.name === 'One-Time Payment')
        if (opt) {
          this.amount = formatNumber(opt.terms.defaultAmount, 2, '', undefined, true)
        } else {
          if (this.payment.paymentOptions.length) {
            this.selectedPaymentOptionId = this.payment.paymentOptions[0].id
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          const unauthorizedError = new UnauthorizedError().init(error.response.data)
          this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
          this.error = unauthorizedError.friendlyMessage || unauthorizedError.header || 'Unauthorized'
        } else {
          this.error = 'There was an error loading your account information'
        }
      })
  }

  componentWillUnmount () {
    this.props.eventBus.remove(this.onAuthenticated)
  }

  private onAuthenticated = () => {
    this.fetchPaymentOptions()
  }

  private renderPaymentOption = (paymentOption: PaymentOption) => {
    if (paymentOption.type === 'button') {
      return <PaymentOptionButton
        key={paymentOption.id}
        variant={this.selectedPaymentOptionId === paymentOption.id ? 'contained' : 'outlined'}
        color="primary"
        fullWidth
        onClick={() => {
          this.selectedPaymentOptionId = paymentOption.id
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: paymentOption.termsMarkup.replace('{payment.amount}', String(paymentOption.terms.defaultAmount ?? 0)) }}/>
      </PaymentOptionButton>
    }
  }

  private renderPaymentForm = (payment: Payment) => {
    return <PaymentFormContainer>
      <PaymentFormContent>
        {
          payment.alert
            ?<Alert severity={payment.alert.severity || undefined} variant={payment.alert.variant || undefined} style={{marginBottom: 20}}>
              {
                payment.alert.title
                  ? <AlertTitle>{payment.alert.title}</AlertTitle>
                  : null
              }
              {payment.alert.body}
            </Alert>
            :null
        }
        <div className="hide-desktop">
          <BalanceHeader>
            {payment.balanceHeader}
          </BalanceHeader>
          <BalanceDue>
            {formatCurrency(payment.balanceDue)}
          </BalanceDue>
        </div>
        <DesktopPaymentHeader className="hide-mobile">
          <DesktopPaymentHeaderTitle>
            Pay Balance Due
          </DesktopPaymentHeaderTitle>
          <DesktopPaymentHeaderBalance>
            {payment.balanceHeader} {formatCurrency(payment.balanceDue)}
          </DesktopPaymentHeaderBalance>
        </DesktopPaymentHeader>
        {
          this.formError
            ? <div>{this.formError}</div>
            : null
        }
        <PaymentFields method="post" onSubmit={this.onSubmit}>
          <PaymentFieldsWrapper>
            {
              this.paymentOptions?.length
                ? <div className="payment-plans">
                  <InputLabel style={{ color: this.props.theme.palette.primary.main, marginBottom: 10, textAlign: 'center' }}>{payment.selectionHeader || 'Select Payment Plan'}</InputLabel>
                  {
                    this.paymentOptions.map(paymentOption => this.renderPaymentOption(paymentOption))
                  }
                </div>
                : null
            }
            <div>
              <InputLabel htmlFor="payment-date" style={{ color: this.props.theme.palette.primary.main }}>{payment.paymentDateHeader || 'Enter Payment Amount'}</InputLabel>
              <div style={{ display: 'flex' }}>
                <div>
                  <DynamicIcon icon={payment.dateIcon} style={{ marginRight: 20, color: this.props?.theme?.palette.primary.main }}/>
                </div>
                <StyledDatePicker
                  id="payment-date"
                  value={this.selectedDate}
                  format="MM/DD/YYYY"
                  onChange={this.onDateChange}
                  maxDate={moment().add(payment.allowedPaymentDateRange, 'days')}
                  disablePast
                  minDateMessage={payment.dateErrorMessage}
                  maxDateMessage={payment.dateErrorMessage}
                  fullWidth
                  disabled={this.submitting}
                  slotProps={{textField: {variant: "standard"}}}
                />
              </div>
            </div>
            {
              this.paymentOption
                ? <div>
                  <InputLabel htmlFor="payment-amount" style={{ color: this.props.theme.palette.primary.main }}>{this.paymentOption?.termsMarkup || 'Enter Payment Amount'}</InputLabel>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <DynamicIcon icon={payment.currencyIcon} style={{ marginRight: 20, color: this.props?.theme?.palette.primary.main }}/>
                    </div>
                    <Input
                      fullWidth
                      id="payment-amount"
                      value={this.amount}
                      onChange={ev => this.amount = ev.target.value}
                      disabled={this.submitting}
                    />
                  </div>
                </div>
                : null
            }
          </PaymentFieldsWrapper>
          <PaymentButton>
            <div className="hide-desktop">
              <Button
                type="submit"
                color="primary"
                variant={payment.postback.variant}
                fullWidth
                disabled={this.submitting}
              >
                {payment.postback.label}
              </Button>
            </div>
            <div className="hide-mobile">
              <Button
                type="submit"
                color="primary"
                disabled={this.submitting}
              >
                {payment.postback.label}
              </Button>
            </div>
          </PaymentButton>
        </PaymentFields>
      </PaymentFormContent>
    </PaymentFormContainer>
  }

  render () {
    return this.error
      ? this.error
      : this.stepperDescriptor
        ? <PaymentStepperContainer>
          <RootRouteContext.Consumer>
            {({ rootRoute }) => <DynamicStepper descriptor={this.stepperDescriptor} rootRoute={rootRoute}/>}
          </RootRouteContext.Consumer>
        </PaymentStepperContainer>
        : this.payment
          ? this.renderPaymentForm(this.payment)
          : <Loader/>
  }
});

const PaymentFormWithTheme = withTheme<Theme, typeof PaymentForm>(PaymentForm) //TODO remove withTheme in favor of useTheme and simplify Types
export default PaymentView
