import {makeObservable, observable} from 'mobx';
import * as ls from 'local-storage'
import {Locale} from "../models/locale";
import i18n from 'i18next';
import {getLocaleFromLanguage} from "../LocalesProvider";

type AuthData = {
  authToken: string
  accessToken: string
  refreshToken: string
  userShort: string
}

class AppStateStore {
  authData?: AuthData;
  activeView?: string;
  // @ts-ignore //TODO check why this is complaining if the property is indeed declared in the .d.ts file
  activeLocale: Locale = getLocaleFromLanguage(i18n.language)

  persistAuthData = (authData: AuthData) => {
    ls.set('authData', JSON.stringify(authData))
    this.authData = authData
  }

  restoreAuthData = () => {
    try {
      this.authData = JSON.parse(ls.get('authData'))
    } catch (e) {
      console.log(e)
    }
  }

  clearAuthData = () => {
    this.authData = undefined
    ls.remove('authData')
  }

  getAuthData = () => {
    this.restoreAuthData();
    return this.authData
  }

  changeLocale = (locale: Locale) => {
    this.activeLocale = locale
    // @ts-ignore //TODO check why this is complaining if the method is indeed declared in the .d.ts file
    i18n.changeLanguage(locale.code)
  }

  constructor() {
    makeObservable(this, {
      authData: observable,
      activeView: observable,
      activeLocale: observable,
    });
  }
}

const state = new AppStateStore()

export default state as AppStateStore
