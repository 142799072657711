import React from "react";
import { observer } from "mobx-react"
import { observable, makeObservable } from "mobx";
import { LinearProgress } from "@mui/material"
import { DynamicStepperProps } from "./DynamicStepperProps"
import DStepper from "./DStepper"
import ApiClientFactory from '../../api/ApiClientFactory'
import { StepperDescriptor } from './models/StepperDescriptor'
import { StepperStepDescriptor } from './models/StepperStepDescriptor'
import DSummary from './DSummary'
import { RouteComponentProps } from "react-router"
import { route } from '../../routes/routes'
import { mapRouteNameToRoute } from '../../routes/AppRoutes'
import { joinUrls } from '../../common/Util'
import {withRouter} from "react-router-dom";

const DynamicStepperBase = observer(
  class DynamicStepperBase extends React.Component<DynamicStepperProps & RouteComponentProps> {
    private loading = false;
    private error?: string;

    private descriptor?: StepperDescriptor;

    constructor(props: DynamicStepperProps & RouteComponentProps) {
      super(props);

      makeObservable<DynamicStepperBase, "loading" | "error" | "descriptor">(this, {
        loading: observable,
        error: observable,
        descriptor: observable
      });
    }

    componentDidMount (): void {
      if (this.props.url) {
        this.loadStepper(this.props.url)
      } else if (this.props.descriptor) {
        this.descriptor = this.props.descriptor
      }
    }

    private loadStepper = async (url: string) => {
      this.loading = true

      try {
        const response = await ApiClientFactory.getInstance()
          .get(url)

        this.descriptor = response.data
      } catch (e) {
        this.error = 'There was an error loading the stepper data'
      }

      this.loading = false
    }

    private handleStepNavigationButton = async (step: StepperStepDescriptor, action: string, postData: any) => {
      if (!step.postbackURL) {
        // TODO: need to handle this?
        return
      }

      const routes: any = {
        'Next': step.nextRoute,
        'Skip': step.skipRoute,
        'Back': step.backRoute,
      }

      if (routes[action]) {
        // route(joinUrls([this.props.match.url, mapRouteNameToRoute(action.quickAction.route)]))

        // need to navigate to a route rather than submit stepper
        this.props.history.push(route(this.props.rootRoute ? joinUrls([this.props.rootRoute, mapRouteNameToRoute(routes[action])]) : mapRouteNameToRoute(routes[action])))
        return
      }

      this.loading = true

      try {
        const response = await ApiClientFactory.getInstance()
          .post(step.postbackURL, postData, {
            headers: {
              'Action': action,
            }
          })

        this.descriptor = response.data
      } catch (e) {
        this.error = 'There was an error loading the stepper data'
      }

      this.loading = false
    }

    private renderLoading = () => {
      return <LinearProgress/>
    }

    private renderError = () => {
      return this.error
    }

    private renderStepper = () => {
      const descriptor = this.descriptor!

      return <div>
        {
          descriptor.summary
            ? <div style={{ marginBottom: 20 }}>
              <DSummary summary={descriptor.summary}/>
            </div>
            : null
        }
        <div className="stepper-content">
          <DStepper
            stepper={descriptor}
            onStepNavigationButtonClicked={this.handleStepNavigationButton}
          />
        </div>
      </div>
    }

    render () {
      return <div>{
        (this.loading || (!this.descriptor && !this.error))
          ? this.renderLoading()
          : this.error
          ? this.renderError()
          : this.renderStepper()
      }</div>
    }
  }
);

const DynamicStepper = withRouter(DynamicStepperBase)

export default DynamicStepper


