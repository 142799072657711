import {styled} from '@mui/system';

export const StatementContainer = styled('div')`
  margin-top: 28px;
  text-align: center;

  ${props => props.theme.breakpoints.up('laptop')} {
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    flex: .6;
    padding-top: 30px;
  }
  ${props => props.theme.breakpoints.up('widescreen')} {
    align-self: flex-start;
  }
`;

export const StatementAmountsContainer = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    margin: 0 10px;
  }
`;

export const StatementHeader = styled('div')`
  margin-bottom: 10px;
`;

export const StatementDate = styled('div')`
  font-weight: bold;

  ${props => props.theme.breakpoints.up('laptop')} {
    margin-top: 10px;
  }
`;

export const BalanceHeader = styled('div')`
  text-transform: uppercase;
`;

export const BalanceDue = styled('div')`
  color: #c00;
  font-weight: bold;
`;

export const StatementAmounts = styled('table')`
  width: 100%;
  text-align: left;
`;

export const PrintButton = styled('div')`
  margin-top: 60px;

  ${props => props.theme.breakpoints.up('laptop')} {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: left;
  }

  .MuiButton-root {
    font-weight: 500 !important;
  }
`;

export const StatementsFilterDesktop = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  ${props => props.theme.breakpoints.down('widescreen')} {
    display: none;
  }

`;

export const StatementLink = styled('a')`
  ${props => props.theme.breakpoints.up('laptop')} {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #333;
    align-items: center;
    padding: 10px;
    border-top: solid 1px #aaa;

    &:last-child {
      border-bottom: solid 1px #aaa;
    }

    &.active {
      background-color: #f6f6f6;
    }
`;

export const StatementFilterIcon = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    margin-right: 16px;
  }
`;

export const StatementFilterText = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    flex: 1;
  }
`;

export const StatementFilterBalance = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    margin-left: 16px;
  }
`;

export const StatementDivider = styled('hr')`
  ${props => props.theme.breakpoints.up('laptop')} {
    margin: 10px;
    border: solid 1px #eee;
  }
`

export const StatementsFilterMobile = styled('div')`
  ${props => props.theme.breakpoints.up('widescreen')} {
    display: none;
  }
`