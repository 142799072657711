import { promiseTimeout } from "./Util"
import Config from "./Config"

let acceptJsScript: HTMLScriptElement

export async function loadAcceptJs (timeout: number = 10000): Promise<AcceptJsClient> {
  return new Promise((resolve, reject) => {
    const win = window as any

    if (typeof win.Accept != 'undefined') {
      resolve(new AcceptJsClient(win.Accept))
      return
    }

    try {
      let handshake = new Promise(resolve => {
        document.body.addEventListener('handshake', () => resolve(new AcceptJsClient(win.Accept)))
      })

      acceptJsScript = document.createElement('script')
      acceptJsScript.src = Config.AUTH_NET_SANDBOX ? 'https://jstest.authorize.net/v1/Accept.js' : 'https://js.authorize.net/v1/Accept.js'
      acceptJsScript.onerror = (event: Event | string) => {
        reject(new Error("Error loading Accept.js"))
      }
      document.body.appendChild(acceptJsScript)

      promiseTimeout(timeout, handshake).then((p: any) => resolve(p), (p) => reject(p))
    } catch {
    }
  })
}

export type CreditCardDataType = {
  cardNumber: string
  month: string
  year: string
  cardCode?: string
  zip?: string
  fullName?: string
}

export type BankAccountDataType = {
  accountType: string
  routingNumber: string
  accountNumber: string
  nameOnAccount: string
}

export type AcceptJsAuthData = {
  clientKey: string
  apiLoginID: string
}

export class AcceptJsClient {
  private client: AcceptJs

  constructor (client: AcceptJs) {
    this.client = client
  }

  tokenizePaymentMethod (authData: AcceptJsAuthData, data: {cardData: CreditCardDataType} | {bankData: BankAccountDataType}) {
    const secureData = {
      ...data,
      authData,
    }

    return new Promise<AcceptJsResponseDataType>((resolve, reject) => {
      this.client.dispatchData(secureData, (response: AcceptJsResponseDataType) => {
        if (response.messages.resultCode === 'Ok') {
          resolve(response)
        } else {
          reject(response)
        }
      })
    })
  }
}
