import React from 'react'
import MaskedInput from 'react-text-mask'


const PhoneNumberInput = React.forwardRef((props, ref) => {
      return (
          <MaskedInput
              {...props}
              ref={(r: any) => {
                ref = r
              }}
              mask={['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
              placeholderChar={'\u2000'}
          />
      );
    }
)

export default PhoneNumberInput;
