import { observer } from 'mobx-react'
import React from 'react'
import { observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import Loader from './Loader'
import { ActionCardsList } from './ActionCardsList'
import { ManagePaymentPlan } from '../models/ManagePaymentPlan'
import { ActionCard } from '../models/ActionCard'
import { getRelUrl } from '../common/Util'
import ApiClient from '../api/ApiClient'
import DialogManager from './dialog-provider/DialogManager'
import EventBus from '../common/EventBus'
import {PaymentActivityHeader} from "../styles/Payment.styles";

type Props = {
  url: string
  eventBus: EventBus
}

const ManagePaymentPlanComponent = observer(class ManagePaymentPlanComponent extends React.Component<Props> {
  private managePaymentPlan?: ManagePaymentPlan;
  private error?: string;

  constructor(props: Props) {
    super(props);

    makeObservable<ManagePaymentPlanComponent, "managePaymentPlan" | "error">(this, {
      managePaymentPlan: observable,
      error: observable
    });
  }

  componentDidMount (): void {
    this.fetchPaymentPlan()
  }

  private fetchPaymentPlan = () => {
    this.managePaymentPlan = undefined
    this.error = undefined

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.managePaymentPlan = new ManagePaymentPlan().init(response.data)
      })
      .catch(error => {
        console.log(error)
        this.error = 'There was an error loading your payment plans'
      })
  }

  private handleQuickAction = (actionCard: ActionCard) => {
    if (actionCard.quickAction?.route === 'CancelConfirmation') {
      const url = getRelUrl(this.managePaymentPlan!.links, actionCard.quickAction.rel)

      DialogManager.show({
        title: 'Cancel Payment Plan',
        content: 'Are you sure you want to cancel this payment plan?',
        buttons: [
          {
            label: 'Yes',
            onClick: close => {
              close()

              ApiClient.getInstance().post(url)
                .then(response => {
                  this.props.eventBus.dispatch('payment-plan-cancelled')
                })
                .catch(() => DialogManager.show({
                  title: 'Error',
                  content: 'There was an error cancelling your payment plan',
                }))
            },
          },
          {
            label: 'No',
            onClick: close => close(),
          },
        ],
      })
    }
  }

  render () {
    return <div>
      {
        this.error
          ? <div>{this.error}</div>
          : this.managePaymentPlan
          ? <>
            <PaymentActivityHeader>
              Payment Plan
            </PaymentActivityHeader>
            <ActionCardsList
              cards={this.managePaymentPlan.current}
              onQuickActionClicked={this.handleQuickAction}
            />
          </>
          : <Loader/>
      }
    </div>
  }
});

export default ManagePaymentPlanComponent
