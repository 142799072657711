import EventBus from '../common/EventBus'
import { observer } from 'mobx-react'
import React from 'react'
import { computed, observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import UnauthorizedError from '../models/UnauthorizedError'
import { PaymentPlans } from '../models/PaymentPlan'
import _ from 'lodash'
import Loader from './Loader'
import { getRelUrl } from '../common/Util'
import ManagePaymentPlanComponent from './ManagePaymentPlanComponent'
import { ActionCardsList } from './ActionCardsList'
import { ActionCard } from '../models/ActionCard'
import {PaymentActivityContainer, PaymentActivityHeader} from "../styles/Payment.styles";

type Props = {
  rootRoute: string
  url: string
  eventBus: EventBus
}

export const PaymentPlansComponent = observer(class PaymentPlansComponent extends React.Component<Props> {
  private paymentPlans?: PaymentPlans;
  private error?: string;
  private selectedTab?: string;
  private selectedPaymentPlan?: ActionCard;

  private eventBus = new EventBus()

  constructor(props: Props) {
    super(props);

    makeObservable<PaymentPlansComponent, "paymentPlans" | "error" | "selectedTab" | "selectedPaymentPlan" | "actionCategories" | "activeTab" | "visiblePaymentPlans">(this, {
      paymentPlans: observable,
      error: observable,
      selectedTab: observable,
      selectedPaymentPlan: observable,
      actionCategories: computed,
      activeTab: computed,
      visiblePaymentPlans: computed
    });
  }

  private get actionCategories() {
    return _.uniq(this.paymentPlans?.current.map(pp => pp.category) ?? [])
  }

  private get activeTab() {
    return this.selectedTab ?? (this.actionCategories.length ? this.actionCategories[0] : undefined)
  }

  private get visiblePaymentPlans() {
    if (!this.paymentPlans) {
      return []
    }

    if (this.actionCategories.length < 2) {
      return this.paymentPlans.current
    }

    return this.paymentPlans.current.filter(pp => pp.category === this.activeTab)
  }

  componentDidMount (): void {
    this.fetchPaymentPlans()

    this.props.eventBus.on('authenticated', this.onAuthenticated)
    this.eventBus.on('payment-plan-cancelled', this.onPaymentPlanCancelled)
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.onAuthenticated)
    this.eventBus.remove(this.onPaymentPlanCancelled)
  }

  private onPaymentPlanCancelled = () => {
    this.selectedPaymentPlan = undefined
    this.fetchPaymentPlans()
  }

  private onAuthenticated = () => {
    this.fetchPaymentPlans()
  }

  private fetchPaymentPlans = () => {
    this.paymentPlans = undefined
    this.error = undefined

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.paymentPlans = new PaymentPlans().init(response.data)
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          const unauthorizedError = new UnauthorizedError().init(error.response.data)
          this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
          this.error = unauthorizedError.friendlyMessage || unauthorizedError.header || 'Unauthorized'
        } else {
          this.error = 'There was an error loading your payment plans'
        }
      })
  }

  handleQuickAction = (actionCard: ActionCard) => {
    if (actionCard.quickAction?.route === 'ManagePaymentPlan') {
      this.selectedPaymentPlan = actionCard
    }
  }

  render () {
    return <PaymentActivityContainer>
      {
        this.error
          ? <div>{this.error}</div>
          : this.paymentPlans
          ? this.selectedPaymentPlan
            ? <>
              <ManagePaymentPlanComponent
                eventBus={this.eventBus}
                url={getRelUrl(this.paymentPlans.links, this.selectedPaymentPlan.quickAction!.rel)}
              />
            </>
            : <>
              <PaymentActivityHeader>
                Payment Plans
              </PaymentActivityHeader>
              {
                !this.paymentPlans.current.length
                  ? <div>{this.paymentPlans.defaultText}</div>
                  : null
              }
              <ActionCardsList
                cards={this.paymentPlans.current}
                onQuickActionClicked={this.handleQuickAction}
              />
            </>
          : <Loader/>
      }
    </PaymentActivityContainer>
  }
});

export default PaymentPlansComponent
