import moment from "moment"
import React, { ReactNode } from "react";
import LinkModel from '../models/Link'
import _ from 'lodash'
import { AxiosResponse } from 'axios'

export function textval (v: any) {
  switch (v) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    default:
      return v
  }
}

export function parseDate (date: string) {
  return date ? moment(date) : date
}

export function formatNumber (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false): string {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
  decSeparator = decSeparator === undefined ? '.' : decSeparator
  thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
  let n: number = c
  let sign = n < 0 ? '-' : ''
  let s = Math.abs(+n || 0)
    .toFixed(decPlaces)
  n = Number(s)
  let i = parseInt(s) + ''
  let j = (i.length > 3) ? (i.length % 3) : 0

  let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
  if (n % 1 !== 0 || forceDecimal) {
    return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
      .toFixed(decPlaces)
      .slice(2) : '')
  } else {
    return str
  }
}

type FormatCurrencyOptions = {
  forceDecimal?: boolean
  formatter?: (value: number, formatted: string) => string | ReactNode
}

export function formatCurrency (c: number, options?: FormatCurrencyOptions): string {
  const opts: FormatCurrencyOptions = {
    forceDecimal: true,
    ...options,
  }
  const formatted = formatNumber(Math.abs(c), 2, undefined, undefined, opts.forceDecimal)

  return opts.formatter
    ? transformIf(opts.formatter(c, `$${formatted}`), (c: any) => c.toString())
    : c < 0 ? `($${formatted})` : `$${formatted}`
}

export function nl2br (str: string) {
  return str.trim().split('\n').map((item, key) => {
    return React.createElement('span', { key }, [React.createElement(React.Fragment, { key: 0 }, [item]), React.createElement('br', { key: 1 })])
  })
}

export function transformIf<T> (o: T | undefined, t: (o: T) => any): any {
  return o ? t(o) : o
}

export function getRelUrl (links: LinkModel[], rel: string) {
  return transformIf<LinkModel>(_.find(links, l => l.rel === rel), l => l.href)
}

export function extractErrorMessage (response: AxiosResponse | any) {
  if (response && response.response) {
    response = response.response
  }

  return ((response && response.data)
    ? (response.data.message)
    : undefined)
    || 'A server error has occurred'
}

export const promiseTimeout = function (ms: number, promise: Promise<any>) {
  let timeoutId: any;
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      reject('Timed out in ' + ms + 'ms.')
    }, ms)
  })

  promise.then(p => {
    clearTimeout(timeoutId)
  }, () => {
    clearTimeout(timeoutId)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ])
}

export const joinUrls = (parts: string[]) => {
  return parts.map((p, idx) => {
    let str = p
    if (idx > 0) {
      str = _.trimStart(str, '/')
    }

    if (idx < parts.length - 1) {
      str = _.trimEnd(str, '/')
    }

    return str
  }).join('/')
}

//Taken from https://wunnle.com/dynamic-text-color-based-on-background

function getRGB(c: string) {
  return parseInt(c, 16)
}

function getsRGB(c: string) {
  return getRGB(c) / 255 <= 0.03928
      ? getRGB(c) / 255 / 12.92
      : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function getLuminance(hexColor: string) {
  return (
      0.2126 * getsRGB(hexColor.substr(1, 2)) +
      0.7152 * getsRGB(hexColor.substr(3, 2)) +
      0.0722 * getsRGB(hexColor.substr(-2))
  )
}

function getContrast(f:string, b:string) {
  const L1 = getLuminance(f)
  const L2 = getLuminance(b)
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export const getTextColor = (bgColor: string) => {
  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}