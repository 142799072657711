import { observer } from 'mobx-react'
import React from 'react'
import { observable, makeObservable } from 'mobx';
import GroupedValues from '../models/GroupedValues'
import { LinearProgress } from '@mui/material'

type Props = {
  adapter: Promise<{data: any}>
}

const GroupedValuesPane = observer(class GroupedValuesPane extends React.Component<Props> {
  private groupedValues?: GroupedValues[];

  constructor(props: Props) {
    super(props);

    makeObservable<GroupedValuesPane, "groupedValues">(this, {
      groupedValues: observable
    });
  }

  componentDidMount (): void {
    this.props.adapter
      .then(response => {
        this.groupedValues = response.data.groupedValues.map((a: any) => new GroupedValues().init(a))
      })
      .catch(error => {
        // TODO: show error
        console.log(error)
      })
  }

  private renderHelp = (groupedValues: GroupedValues[]) => {
    return groupedValues.map((gv, idx) => <div key={idx}>
      <h4>{gv.group}</h4>
      {gv.values.map((v, idx) => <div key={idx}>
        <h4>{v.label}</h4>
        <div dangerouslySetInnerHTML={{ __html: v.value }}/>
      </div>)}
    </div>)
  }

  private renderLoader = () => {
    return <LinearProgress/>
  }

  render () {
    return <div style={{ width: '100%' }}>
      {
        this.groupedValues
          ? this.renderHelp(this.groupedValues)
          : this.renderLoader()
      }
    </div>
  }
});

export default GroupedValuesPane
