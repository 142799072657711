import React, {SyntheticEvent} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import './App.scss';
import {BrowserRouter as Router, Route, RouteComponentProps, Switch, useParams, withRouter} from 'react-router-dom'
import {
  AppBar,
  BottomNavigationAction,
  CircularProgress,
  createTheme,
  IconButton,
  Link,
  Menu,
  MenuItem,
  StyledEngineProvider,
  Theme,
  Toolbar
} from "@mui/material"
import {observer} from "mobx-react"
import ApiClientFactory from './api/ApiClientFactory'
import ApiClient from './api/ApiClient'
import MenuIcon from '@mui/icons-material/Menu'
import {autorun, computed, makeObservable, observable, toJS} from 'mobx';
import Interaction from './models/Interaction'
import {AppRoutes, mapRouteNameToRoute, Routes} from './routes/AppRoutes'
import NotFoundView from './views/NotFoundView'
import {InteractionContext} from './InteractionContext'
import AdapterLink from './components/AdapterLink'
import {route} from './routes/routes'
import QuickAction from './models/QuickAction'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import EventBus, {EventBusContext} from './common/EventBus'
import LoginDialog from './components/LoginDialog'
import AppStateStore from './stores/AppStateStore'
import DialogProvider from './components/dialog-provider/DialogProvider'
import classNames from 'classnames'
import DynamicIcon from './components/DynamicIcon'
import {TreeItem, TreeView} from '@mui/lab'
import Config from './common/Config'
import {joinUrls} from './common/Util'
import {RootRouteContext} from './RootRouteContext'
import GenericLoginView from './views/GenericLoginView'
import {CommonContext} from './CommonContext';
import _ from 'lodash'
import {
  AppClientLogo,
  AppContainer,
  AppContent,
  AppContentWrapper,
  AppFooter,
  AppLoaderContainer,
  AuthNavBar,
  HeaderHelpLink,
  LowerAuthBar,
  MobileBottomNav
} from './styles/App.styles';
import {baseTheme} from "./base-theme";
import {
  DesktopAuthHeader,
  DesktopClientLogo,
  DesktopHeader,
  DesktopLeftNav,
  DesktopLeftNavItem,
  DesktopNavIcon
} from "./styles/Desktop.styles";
import './i18n';
import {LocaleSelector} from "./components/LocaleSelector/LocaleSelector";
import {WithTranslation, withTranslation} from "react-i18next";
//import 'moment/locale/es-mx';
import {supportedLocales} from "./LocalesProvider";
import {Redirect} from "react-router";
import {generateManifest, loadManifest, ManifestAppData} from "./common/manifest-generator";

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

ApiClientFactory.setInstance(ApiClient)


const CurrentUserRedirect: React.FC = () => {
  const { slug } = useParams();
  return <Redirect to={AppStateStore.getAuthData()?.userShort ? `/${AppStateStore.getAuthData()?.userShort}/${slug || ""}`: "/"}/>
}


const App = observer(class App extends React.Component {
  render () {
    return <Router>
      <Switch>
        <Route
          component={GenericLoginView}
          path={['/', Routes.terms, Routes.policy, Routes.help, Routes.unsubscribe]}
          exact={true}
        />
        {/*Not sure why removing this doesn't match correctly with the subsequent rule*/}
        <Route
            component={CurrentUserRedirect}
            path="/current"
            exact={false}
            sensitive={true}
            strict={false}
        />
        <Route
            component={CurrentUserRedirect}
            path="/current/:slug"
            exact={false}
            sensitive={true}
            strict={false}
        />
        <Route
          component={AppComponent}
          path="/:slug"
          exact={false}
          sensitive={true}
          strict={true}
        />
        <Route component={NotFoundView}/>
      </Switch>
    </Router>
  }
});

const AppComponent = observer(
  class AppComponent extends React.Component<RouteComponentProps<{slug: string}>> {
    private interaction?: Interaction;
    private eventBus = new EventBus()

    constructor(props: RouteComponentProps<{slug: string}>) {
      super(props);

      makeObservable<AppComponent, "interaction">(this, {
        interaction: observable,
        theme: computed
      });
    }

    get theme() {
      const palette: any = {
        primary: { main: '#3c2d82' },
      }

      if (this.interaction && this.interaction.client) {
        this.interaction.client.colors.forEach(color => {
          palette[color.type] = {
            main: color.code.hex
          }
        })
      }

      return createTheme({
        ...baseTheme,
        palette: palette,
      });
    }

    private getInteraction = async () => {
      const params: any = {
        data: this.props.match.params.slug,
      }

      if (Config.API_SUB_ID) {
        params.sub = Config.API_SUB_ID
      }

      return ApiClientFactory.getInstance()
        .get(`https://e65d2wtk52.execute-api.us-east-1.amazonaws.com/dev/interaction`, { params })
    }

    private renderLoader = () => {
      return <AppLoaderContainer>
        <CircularProgress/>
      </AppLoaderContainer>
    }

    private renderFooter = (interaction: Interaction) => {
      return (
        <AppFooter>
          <Link
            color="inherit"
            component={AdapterLink}
            to={route(joinUrls([this.props.match.url, Routes.policy]))}
            underline="hover">{interaction.policy.label}</Link>
          &nbsp;
          &nbsp;
          &nbsp;
          <Link
            color="inherit"
            component={AdapterLink}
            to={route(joinUrls([this.props.match.url, Routes.terms]))}
            underline="hover">{interaction.term.label}</Link>
        </AppFooter>
      );
    }

    private setFavIcon (src: string | undefined) {
      let link: any = document.querySelector("link[rel~='icon']")

      if (!src && link) {
        link.remove()
      }

      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }

      link.href = src
    }

    componentDidMount (): void {
      AppStateStore.restoreAuthData()

      let prevAuthData: any = undefined

      autorun(() => {
        // need to update when auth token changes
        const authData = toJS(AppStateStore.authData)

        if (!_.isEqual(prevAuthData, authData)) {
          // auth data has changed, need to reload the interaction

          prevAuthData = authData
          this.getInteraction()
            .then(response => {
              this.interaction = new Interaction().init(response.data)
              this.interaction.fetchQuickActions()
              this.interaction.fetchClient()
            })
            .catch(error => {
              // TODO: show error
              console.log(error)
            })
        }
      })

      autorun(() => {
        document.title = this.interaction?.client?.shortName || 'Patient App'
        const client = this.interaction?.client;
        this.setFavIcon(client?.faviconSource)
        //loadManifest(client as ManifestAppData)
      })

      this.getInteraction()
        .then(response => {
          this.interaction = new Interaction().init(response.data)
          this.interaction.fetchQuickActions()
          this.interaction.fetchClient()
        })
        .catch(error => {
          // TODO: show error
          console.log(error)
        })
    }

    render () {
      return (this.interaction && this.interaction.client)
        ? this.renderApp(this.interaction)
        : this.renderLoader()
    }

    private renderApp = (interaction: Interaction) => {
      const AppRouteSwitchRouter = withRouter(AppRouteSwitch)

      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={this.theme}>
            <RootRouteContext.Provider value={{ rootRoute: this.props.match.url }}>
              <DialogProvider>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={AppStateStore.activeLocale.code.toLowerCase()} localeText={AppStateStore.activeLocale.muiLocaleText}>
                  <AppContainer className={classNames([AppStateStore.authData ? 'is-authenticated' : undefined])}>
                    <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
                      <InteractionContext.Provider value={{ interaction: interaction }}>
                        <CommonContext.Provider value={{ commonData: interaction }}>
                          <DesktopHeader className="hide-mobile">
                            {
                              interaction?.client?.logoSource
                                ? <DesktopClientLogo>
                                  <img src={interaction.client.logoSource} style={{ maxWidth: '100%', maxHeight: '100%' }} alt=""/>
                                </DesktopClientLogo>
                                : null
                            }
                            <DesktopAuthHeader>
                              <AuthHeaderRouter rootRoute={this.props.match.url}/>
                            </DesktopAuthHeader>
                          </DesktopHeader>
                          <div className="hide-desktop">
                            {
                              AppStateStore.authData
                                ? <AuthHeaderRouter rootRoute={this.props.match.url}/>
                                : <AppMenuRouter
                                  rootRoute={this.props.match.url}
                                  position="top"
                                  quickActions={interaction.quickActions}
                                  theme={this.theme}
                                />
                            }
                          </div>
                          {
                            (interaction.client && interaction.client.logoSource)
                              ?
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="hide-desktop">
                                <AppClientLogo>
                                  <img src={interaction.client.logoSource} style={{ maxWidth: '100%', maxHeight: '100%' }} alt=""/>
                                </AppClientLogo>
                              </div>
                              : null
                          }
                          <AppContentWrapper>
                            <DesktopLeftNav className="hide-mobile">
                              <AppMenuRouter
                                rootRoute={this.props.match.url}
                                position="left"
                                quickActions={interaction.quickActions}
                                theme={this.theme}
                              />
                            </DesktopLeftNav>
                            <AppContent
                              className={classNames([AppStateStore.authData ? 'is-authenticated' : undefined])}>
                              <AppRouteSwitchRouter/>
                            </AppContent>
                          </AppContentWrapper>
                          {interaction ? <LoginDialog goBackOnCancel={true} eventBus={this.eventBus} client={interaction.client}/> : null}
                          {
                            AppStateStore.authData
                              ? <>
                                <div className="bottom-navigation">
                                  <AppMenuRouter
                                    rootRoute={this.props.match.url}
                                    position="bottom"
                                    quickActions={interaction.quickActions}
                                    theme={this.theme}
                                  />
                                </div>
                                <div className="hide-mobile">
                                  {this.renderFooter(interaction)}
                                </div>
                              </>
                              : <div>
                                {this.renderFooter(interaction)}
                              </div>

                          }
                        </CommonContext.Provider>
                      </InteractionContext.Provider>
                    </EventBusContext.Provider>
                  </AppContainer>
                </LocalizationProvider>
              </DialogProvider>
            </RootRouteContext.Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }
  }
);

const AppRouteSwitch = observer(class AppRouteSwitch extends React.Component<RouteComponentProps> {
  render () {
    return <Switch>
      {AppRoutes.map(r => {
        const { component, path, ...rest } = r
        return (
          <Route
            key={path}
            path={`${this.props.match.path}${r.path}`}
            component={component}
            {...rest}
          />
        )
      })}
      <Route component={NotFoundView}/>
    </Switch>
  }
});

type AuthHeaderProps = {
  rootRoute: string
} & RouteComponentProps & WithTranslation

const AuthHeader = withTranslation()(observer(class AuthHeader extends React.Component<AuthHeaderProps> {
  render () {
    const {t} = this.props;
    return (
      <AuthNavBar>
        {
          AppStateStore.authData
            ? <HeaderHelpLink>
              <Link
                color="inherit"
                style={{ display: 'flex', alignItems: 'center' }}
                href=""
                onClick={(ev: SyntheticEvent) => {
                  ev.preventDefault()
                  AppStateStore.clearAuthData()
                  this.props.history.push('/')
                }}
                underline="hover">{t('global.logout')}</Link>
            </HeaderHelpLink>
            : null
        }
        <LowerAuthBar>
          {/* <LocaleSelector locales={supportedLocales} activeLocale={AppStateStore.activeLocale} onLocaleChange={AppStateStore.changeLocale}/> */}
          <Link
            component={AdapterLink}
            color="inherit"
            style={{ display: 'flex', alignItems: 'center' }}
            to={route(joinUrls([this.props.rootRoute, Routes.help]))}
            underline="hover">{t('global.needHelp')}</Link>
        </LowerAuthBar>
      </AuthNavBar>
    );
  }
}));

export const AuthHeaderRouter = withRouter(AuthHeader)

type AppMenuProps = {
  rootRoute: string
  position: 'top' | 'bottom' | 'left'
  quickActions: QuickAction[]
  theme: Theme
} & RouteComponentProps

const AppMenu = observer(class AppMenu extends React.Component<AppMenuProps> {
  private isMenuOpen = false;
  private showOverflowMenu = false;
  private menuIconRef = React.createRef<HTMLButtonElement>()

  private overflowButtonRef = React.createRef<HTMLButtonElement>()

  constructor(props: AppMenuProps) {
    super(props);

    makeObservable<AppMenu, "isMenuOpen" | "showOverflowMenu">(this, {
      isMenuOpen: observable,
      showOverflowMenu: observable,
      activeView: computed
    });
  }

  get activeView() {
    return AppStateStore.activeView || ''
  }

  private handleMenuClose = () => {
    this.isMenuOpen = false
  }

  private renderLeftMenu () {
    return <DesktopLeftNav className="hide-mobile">
      <TreeView
        selected={this.activeView}
        onNodeToggle={() => {
        }}
        onNodeSelect={() => {
        }}
      >
        {
          this.props.quickActions.map(quickAction => {
            return <TreeItem
              key={quickAction.rel}
              nodeId={quickAction.route}
              label={<DesktopLeftNavItem style={this.activeView === quickAction.route ? { color: this.props.theme.palette.primary.main } : undefined}>
                <DesktopNavIcon icon={quickAction.icon}/> <span className="nav-label">{quickAction.label}</span>
              </DesktopLeftNavItem>}
              onClick={() => {
                this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
              }}
            />
          })
        }
      </TreeView>
    </DesktopLeftNav>
  }

  private renderTopMenu () {
    return (
      <AppBar className="mobile-top-nav hide-desktop" position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            ref={this.menuIconRef}
            onClick={() => this.isMenuOpen = true}
            size="large">
            <MenuIcon/>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={this.menuIconRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={this.isMenuOpen}
            onClose={this.handleMenuClose}
          >
            {
              this.props.quickActions.map(quickAction => <MenuItem
                key={quickAction.rel}
                onClick={() => {
                  this.handleMenuClose()
                  this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
                }}
              >{quickAction.label}</MenuItem>)
            }
          </Menu>
          <Link
            component={AdapterLink}
            color="inherit"
            style={{ marginLeft: 'auto', fontSize: '10pt' }}
            to={route(joinUrls([this.props.rootRoute, Routes.help]))}
            underline="hover">Need Help?</Link>
        </Toolbar>
      </AppBar>
    );
  }

  private renderBottomMenu () {
    const quickActions = this.props.quickActions.slice()
    let overflowActions: QuickAction[] = []

    if (this.props.quickActions.length > 4) {
      overflowActions = quickActions.splice(3)
    }

    return <>
      <MobileBottomNav
        value={AppStateStore.activeView}
        className="hide-desktop"
        onChange={(event, newValue) => {
          // setValue(newValue);
        }}
        showLabels
      >
        {
          quickActions.map(quickAction => {
            return <BottomNavigationAction
              key={quickAction.rel}
              value={quickAction.route}
              onClick={() => {
                this.handleMenuClose()
                this.props.history.push(route(joinUrls([this.props.match.url, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
              }}
              label={quickAction.label}
              icon={<DynamicIcon icon={quickAction.icon}/>}
            />
          })
        }
        {
          overflowActions.length
            ? <BottomNavigationAction
              ref={this.overflowButtonRef}
              key="__overflow"
              label="More"
              icon={<DynamicIcon icon="MoreHoriz"/>}
              onClick={() => {
                this.showOverflowMenu = !this.showOverflowMenu
              }}
            />
            : null
        }
      </MobileBottomNav>
      {
        overflowActions.length
          ? <Menu
            anchorEl={this.overflowButtonRef.current}
            open={this.showOverflowMenu}
            keepMounted
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={() => this.showOverflowMenu = false}
          >
            {
              overflowActions.map(quickAction => <MenuItem
                key={quickAction.rel}
                onClick={() => {
                  this.showOverflowMenu = false
                  this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(quickAction.route)]), {}, { rel: quickAction.rel }))
                }}
              >{quickAction.label}</MenuItem>)
            }
          </Menu>
          : null
      }
    </>
  }

  render () {
    switch (this.props.position) {
      case 'bottom':
        return this.renderBottomMenu()
      case 'top':
        return this.renderTopMenu()
      case 'left':
        return this.renderLeftMenu()
    }

    return null
  }
});

export const AppMenuRouter = withRouter(AppMenu)
export default App
