import {styled} from '@mui/system';
import {FormGroup} from "@mui/material";

export const StepperCheckboxField = styled(FormGroup)`
.MuiFormControlLabel-root {
    align-items: flex-start;
  }
`

export const DynamicForm = styled('form')`
  .MuiGrid-container{
    .MuiGrid-item:first-child {
      padding-top: 8px;
    }
  }
  
  .MuiFormLabel-root{
    line-height: 1.4375em;
  }
`