import React from 'react'
import { observer } from 'mobx-react'
import AppStateStore from '../stores/AppStateStore'
import { joinUrls, transformIf } from '../common/Util'
import queryString from 'query-string'
import { InteractionContext } from '../InteractionContext'
import { Theme } from '@mui/material'
import { withTheme } from '@mui/styles'
import { observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import Loader from '../components/Loader'
import { PaymentActions } from '../models/PaymentActions'
import { ActionCardsList } from '../components/ActionCardsList'
import { ActionCard } from '../models/ActionCard'
import { route } from '../routes/routes'
import { mapRouteNameToRoute } from '../routes/AppRoutes'
import { RouteComponentProps } from 'react-router-dom'
import * as H from 'history'
import { RootRouteContext } from '../RootRouteContext'
import {PaymentActionsContainer} from "../styles/Container.styles";

const PaymentActionsView = observer(class PaymentActionsView extends React.Component<RouteComponentProps> {
  componentDidMount (): void {
    AppStateStore.activeView = 'PaymentActions'
  }

  render () {
    const rel = transformIf(queryString.parse(window.location.search).rel, t => String(t))
    return <RootRouteContext.Consumer>{({ rootRoute }) =>
      <InteractionContext.Consumer>
        {interactionContext => transformIf(interactionContext.interaction.getQuickActionLinkByRel(rel ?? 'paymentactions'), link => <PaymentActionsWithTheme history={this.props.history} url={link.href} rootRoute={rootRoute}/>)}
      </InteractionContext.Consumer>
    }</RootRouteContext.Consumer>
  }
});

type PaymentActionProps = {
  url: string
  theme: Theme
  history: H.History
  rootRoute: string
}

const PaymentActionsComponent = observer(
  class PaymentActionsComponent extends React.Component<PaymentActionProps> {
    paymentActions?: PaymentActions;
    error?: string;

    constructor(props: PaymentActionProps) {
      super(props);

      makeObservable(this, {
        paymentActions: observable,
        error: observable
      });
    }

    componentDidMount (): void {
      ApiClientFactory.getInstance()
        .get(this.props.url)
        .then(response => {
          const paymentActions = new PaymentActions().init(response.data)

          // TODO: this is a temporary workaround to deal with incorrect API response
          paymentActions.current.forEach(actionCard => {
            actionCard.rows = actionCard.rows.map(row => {
              if (!row.value) {
                const { type, ...rest } = row
                return {
                  type,
                  value: rest,
                }
              } else {
                return row
              }
            }) as any
          })

          this.paymentActions = paymentActions
        })
        .catch(error => this.error = 'There was an error loading your account information')
    }

    private handleQuickAction = (actionCard: ActionCard) => {
      this.props.history.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(actionCard.quickAction!.route)]), {}, { rel: actionCard.quickAction!.rel }))
    }

    render () {
      return this.error
        ? this.error
        : this.paymentActions
          ? <PaymentActionsContainer>
            <ActionCardsList
              collapseMobile={true}
              greeting={this.paymentActions.greeting}
              text={this.paymentActions.text}
              detail={this.paymentActions.detail}
              cards={this.paymentActions.current}
              onQuickActionClicked={this.handleQuickAction}
            />
          </PaymentActionsContainer>
          : <Loader/>
    }
  }
);

const PaymentActionsWithTheme = withTheme<Theme, typeof PaymentActionsComponent>(PaymentActionsComponent) //TODO remove withTheme in favor of useTheme and simplify Types
export default PaymentActionsView
