import { observer } from 'mobx-react'
import React from 'react'
import { observable, makeObservable } from 'mobx';
import { TransitionProps } from '@mui/material/transitions'
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material'
import GroupedValuesPane from './GroupedValuesPane'
import ApiClientFactory from '../api/ApiClientFactory'

type Props = {
  url: string
  onExited: () => void
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement<any, any>; }>(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const GroupedValuesDialog = observer(class GroupedValuesDialog extends React.Component<Props> {
  showDialog = true;

  private getGroupedValues = async () => {
    return ApiClientFactory.getInstance()
      .get(this.props.url)
  }

  private handleDialogClose = () => {
    this.showDialog = false
  }

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      showDialog: observable
    });
  }

  render () {
    return (
      <Dialog
        open={this.showDialog}
        onClose={this.handleDialogClose}
        TransitionComponent={Transition}
        maxWidth={this.props.maxWidth}
        fullWidth
        TransitionProps={{
          onExited: this.props.onExited
        }}>
        <DialogContent>
          <div>
            <GroupedValuesPane adapter={this.getGroupedValues()}/>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
});

export default GroupedValuesDialog
