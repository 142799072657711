import * as React from "react"
import { Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { computed, observable, makeObservable } from "mobx";
import _ from "lodash"
import DStepButtons from "./DStepButtons"
import { observer } from "mobx-react"
import DForm from "./DForm"
import { StepperStepDescriptor } from './models/StepperStepDescriptor'
import { StepperDescriptor, StepperOrientation } from './models/StepperDescriptor'

type DStepperProps = {
  stepper: StepperDescriptor
  onStepNavigationButtonClicked: (step: StepperStepDescriptor, action: string, postData: any) => void
}

type NavigationListenerFn = (action: string, next: () => void) => boolean

export class NavigationListener {
  private listeners: NavigationListenerFn[] = []

  addListener = (listener: NavigationListenerFn) => {
    if (this.listeners.indexOf(listener) === -1) {
      this.listeners.push(listener)
    }
  }

  removeListener = (listener: NavigationListenerFn) => {
    this.listeners = this.listeners.filter(l => l !== listener)
  }

  handleNavigationButton = (action: string, next: () => void) => {
    for (let i = 0; i < this.listeners.length; ++i) {
      if (this.listeners[i](action, next)) {
        return true
      }
    }

    return false
  }
}

const DStepper = observer(class DStepper extends React.Component<DStepperProps> {
  formData: any = {};
  private navigationListener = new NavigationListener()
  private isMobile = false;

  constructor(props: DStepperProps) {
    super(props);

    makeObservable<DStepper, "isMobile">(this, {
      formData: observable,
      isMobile: observable,
      orientation: computed,
      activeStep: computed,
      activeStepIndex: computed
    });
  }

  get orientation() {
    return this.props.stepper.orientation
  }

  componentDidMount (): void {
    if (this.activeStep && this.activeStep.content.form) {
      this.formData = this.activeStep.content.form.data
    }

    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.onResize)
  }

  private onResize = (ev: UIEvent) => {
    this.checkIsMobileWidth()
  }

  private checkIsMobileWidth = () => {
    this.isMobile = window.innerWidth < 1024
  }

  get activeStep() {
    return _.find<StepperStepDescriptor>(this.props.stepper.steps, s => s.active)
  }

  get activeStepIndex() {
    return _.findIndex(this.props.stepper.steps, s => s.active)
  }

  private handleStepNavigationButton = (step: StepperStepDescriptor, action: string, postData: any) => {
    if (!this.navigationListener.handleNavigationButton(action, () => this.props.onStepNavigationButtonClicked(step, action, this.formData))) {
      this.props.onStepNavigationButtonClicked(step, action, this.formData)
    }
  }

  private onFormDataChanged = (formData: any) => {
    console.log(formData)
  }

  private renderStepContent = (step: StepperStepDescriptor) => {
    if (step.content.stepper) {
      return <DStepper
        stepper={step.content.stepper}
        onStepNavigationButtonClicked={this.props.onStepNavigationButtonClicked}
      />
    } else if (step.content.form) {
      return <div>
        <DForm
          links={this.props.stepper.links}
          onFormDataChanged={this.onFormDataChanged}
          form={step.content.form}
          navigationListener={this.navigationListener}
        />
      </div>
    } else {
      return null
    }
  }

  render () {
    const { stepper } = this.props
    const activeStep = this.activeStep

    return <>
      <Stepper orientation={this.orientation} activeStep={this.activeStepIndex}>
        {
          stepper.steps.map(step => <Step key={step.id}>
            <StepLabel
              alternativeLabel={this.orientation === StepperOrientation.Horizontal}
              StepIconProps={{completed: step.complete}}
            >{step.label}</StepLabel>
            {
              (this.orientation === StepperOrientation.Vertical && step.active)
                ? <StepContent>
                  {this.renderStepContent(step)}
                  <DStepButtons
                    stepIndex={this.activeStepIndex}
                    step={step}
                    onNavigationButtonClicked={(action, postData) => this.handleStepNavigationButton(step, action, postData)}
                  />
                </StepContent>
                : null
            }
          </Step>)
        }
      </Stepper>
      {
        (this.orientation === StepperOrientation.Horizontal && activeStep)
          ? <>
            {this.renderStepContent(activeStep)}
            <DStepButtons
              stepIndex={this.activeStepIndex}
              buttonVariant="contained"
              step={activeStep}
              onNavigationButtonClicked={(action, postData) => this.handleStepNavigationButton(activeStep, action, postData)}
            />
          </>
          : null
      }
    </>
  }
});

export default DStepper
