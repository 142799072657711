import { observer } from "mobx-react"
import * as React from "react"
import { TextField } from "@mui/material"

type Props = {
  id: string
  title: string
  value: string
  onChange: (value: string) => void
}

const DFormTextField = observer(class DFormTextField extends React.Component<Props> {
  onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(ev.target.value)
  }

  render () {
    return (
      <TextField
        variant="standard"
        id={this.props.id}
        label={this.props.title}
        value={this.props.value}
        fullWidth />
    );
  }
});

export default DFormTextField
