import React from 'react'
import {InteractionContext} from '../InteractionContext'
import {getRelUrl, joinUrls} from '../common/Util'
import {observer} from 'mobx-react'
import {makeObservable, observable} from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import {Button} from '@mui/material'
import AdapterLink from '../components/AdapterLink'
import {mapRouteNameToRoute} from '../routes/AppRoutes'
import {route} from '../routes/routes'
import Loader from '../components/Loader'
import AppStateStore from '../stores/AppStateStore'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {ActionCard} from '../components/ActionCard'
import {ActionCard as ActionCardModel} from '../models/ActionCard'
import {ActionDetail, Header3, Header4, HomeGreeting, HomeText} from "../styles/App.styles";
import {CustomActionButton, CustomActionContainer, CustomActionList} from "../styles/HomeView.styles";
import {HomeContainer} from "../styles/Container.styles";
import {withTranslation, WithTranslation} from "react-i18next";

const HomeView = observer(class HomeView extends React.Component {
  componentDidMount (): void {
    AppStateStore.activeView = 'Home'
  }

  render () {
    const ActionListRouter = withRouter(ActionList)

    return <InteractionContext.Consumer>
      {interaction => <HomeContainer>
        <ActionListRouter url={getRelUrl(interaction.interaction.links, 'actions')}/>
      </HomeContainer>}
    </InteractionContext.Consumer>
  }
});

type ActionListProps = {
  url: string
} & RouteComponentProps & WithTranslation

const ActionList = withTranslation()(observer(class ActionList extends React.Component<ActionListProps> {
  private greeting: string | undefined = undefined;
  private text: string | undefined = undefined;
  private detail: {
    imageSource: string | undefined
    label: string | undefined
    value: string | undefined
  } | undefined = undefined;
  private actions: ActionCardModel[] = [];
  private loading = false;

  constructor(props: ActionListProps) {
    super(props);

    makeObservable<ActionList, "greeting" | "text" | "detail" | "actions" | "loading">(this, {
      greeting: observable,
      text: observable,
      detail: observable,
      actions: observable,
      loading: observable
    });
  }

  componentDidMount (): void {
    this.loading = true;

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.actions = response.data.current.map((a: any) => new ActionCardModel().init(a))
        this.greeting = response.data.greeting
        this.text = response.data.text
        this.detail = response.data.detail
        this.loading = false
      })
  }

  private renderMobileAction = (action: ActionCardModel) => {
    return <CustomActionContainer>
      {
        action.quickAction
          ? <CustomActionButton>
            <Button
              fullWidth
              component={AdapterLink}
              to={route(joinUrls([this.props.match.url, mapRouteNameToRoute(action.quickAction.route)]), {}, { rel: action.quickAction.rel })}
              color="primary"
              variant={action.quickAction.variant}
            >
              {action.quickAction.label}
            </Button>
          </CustomActionButton>
          : null
      }
    </CustomActionContainer>
  }

  private renderDesktopAction = (action: ActionCardModel) => {
    return <ActionCard collapseMobile={true} actionCard={action} onQuickActionClicked={actionCard => {
      this.props.history.push(route(joinUrls([this.props.match.url, mapRouteNameToRoute(actionCard.quickAction!.route)]), {}, { rel: actionCard.quickAction!.rel }))
    }}/>
  }

  render () {
    return this.loading
      ? <Loader/>
      : <>
        {
          this.greeting
          ? <HomeGreeting>{this.greeting /*TODO Translate t("home.greeting", {name: this.greeting.name})*/}</HomeGreeting>
            : null
        }
        {
          this.text
            ? <HomeText className="hide-mobile">{this.text/*TODO Translate t("home.summary")*/}</HomeText>
            : null
        }
        {
          this.detail
            ? <ActionDetail className="hide-desktop">
              <Header3>{this.detail.label}</Header3>
              <Header4>{this.detail.value}</Header4>
            </ActionDetail>
            : null
        }
        <CustomActionList quad={this.actions.length > 3}>
        {this.actions.map((action, idx) => <div key={idx}>
            <div className="hide-desktop">{this.renderMobileAction(action)}</div>
            <div className="hide-mobile">{this.renderDesktopAction(action)}</div>
          </div>
        )}
      </CustomActionList>
        </>
  }
}));

export default HomeView
