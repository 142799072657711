import {styled} from '@mui/system';

export const LoginForm = styled('div')`
  max-width: 600px;
  padding: 0 40px;
  margin: auto;

  ${props => props.theme.breakpoints.up('laptop')} {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 0;
  }
`;

export const LoginHeader = styled('div')`
  text-align: center;
  font-weight: bold;
`;

export const LoginFields = styled('form')`
  margin-top: 40px;

  > div {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const LoginDialogLeft = styled('div')`
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('laptop')} {
    margin-bottom: 10px;
  }
`

export const LoginDialogLogo = styled('div')`
    margin: 0 auto;
    width: 250px;
    height: 80px;
    margin-top: 20px;
    margin-bottom: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
`

export const LoginDialogRight = styled('div')`
  flex: 1;
  border-left: solid 1px #999;
  padding: 20px;
`

export const LoginDialogContent = styled('div')`
    display: flex;
`

export const GenericLoginContainer = styled('div')`
  .MuiFormLabel-root{
    line-height: 1.4375em;
  }
  ${props => props.theme.breakpoints.down('laptop')}{
    padding: 0 20px;
  }
  ${props => props.theme.breakpoints.up('laptop')}{
    box-sizing: border-box;
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    padding: 30px 50px;
    max-width: 700px;
    margin: 50px auto 0;
  }
  
  
`

