import * as React from "react"
import { observer } from "mobx-react"
import { Card, CardContent, Grid } from "@mui/material"

type Props = {
  client: string
  reference: string
  name: string
  address: string
  phone: string
  amount: string
}

const DAccountCard = observer(class DAccountCard extends React.Component<Props> {
  render () {
    return <Card>
      <CardContent>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div>{this.props.client}</div>
            <Grid container>
              <Grid item xs={4}>
                {this.props.reference}<br/>
                {this.props.name}
              </Grid>
              <Grid item xs={4}>
                <div dangerouslySetInnerHTML={{ __html: this.props.address }}/>
              </Grid>
              <Grid item xs={4}>
                {this.props.phone}
              </Grid>
            </Grid>
          </div>
          <div style={{ textAlign: 'right' }}>
            {this.props.amount}<br/>
          </div>
        </div>
      </CardContent>
    </Card>
  }
});

export default DAccountCard
