import {ThemeOptions} from "@mui/material";

export const baseTheme: ThemeOptions = {
    components:{
        MuiButton:{
            styleOverrides:{
                root: ({ownerState})=> ({
                    ...(ownerState.fullWidth && {
                        fontWeight: 'normal',
                        textTransform: 'none',
                        lineHeight: '2',
                        fontSize: '.9rem',
                    })
                })
            },
        },
        MuiPaper:{
            styleOverrides:{
                root: ({ownerState})=> ({
                    ...(ownerState.rounded && {
                        borderRadius: '12px',
                    })
                })
            },
        },
        MuiAutocomplete:{
            styleOverrides:{
                inputRoot: ({ownerState})=> ({
                    ...(ownerState.hasPopupIcon && ownerState.hasClearIcon && {
                        paddingRight: '22px',
                    })
                })
            },
        },
        MuiTab:{
            styleOverrides:{
                root: ({theme})=> ({
                    [theme.breakpoints.down('laptop')]: {
                        padding: '0',
                        fontSize: '12px',
                    },
                })
            }
        },
        MuiStepConnector:{
            styleOverrides:{
                root: {
                    padding: '0 0 8px',
                }
            }
        },
        MuiStepContent:{
            styleOverrides:{
                root: {
                    marginTop: '8px',
                }
            }
        },
        MuiStepLabel:{
            styleOverrides:{
                root: {
                    padding: '0',
                }
            }
        },
        MuiFormLabel:{
            styleOverrides:{
                root: {
                    lineHeight: '1',
                }
            }
        }

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
            widescreen:1420,
        },
    }
};

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobile: true;
        tablet: true;
        laptop: true;
        desktop: true;
        widescreen: true;
    }
}