import EventBus, { UnauthorizedErrorEvent } from '../common/EventBus'
import { observer } from 'mobx-react'
import React, { SyntheticEvent } from 'react'
import { autorun, computed, observable, makeObservable } from 'mobx';
import UnauthorizedError from '../models/UnauthorizedError'
import { TransitionProps } from '@mui/material/transitions'
import { AppBar, Button, Checkbox, Dialog, DialogContent, FormHelperText, IconButton, Input, InputLabel, Link, Slide, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Authentication from '../models/Authentication'
import ApiClientFactory from '../api/ApiClientFactory'
import { getRelUrl } from '../common/Util'
import Loader from './Loader'
import GroupedValuesDialog from './GroupedValuesDialog'
import AppStateStore from '../stores/AppStateStore'
import moment, { Moment } from 'moment'
import DatePickerDropdown from './DatePickerDropdown'
import _ from 'lodash'
import Client from '../models/Client'
import {
  LoginDialogContent,
  LoginDialogLeft,
  LoginDialogLogo, LoginDialogRight,
  LoginFields,
  LoginForm,
  LoginHeader
} from "../styles/LoginForm.styles";

type Props = {
  eventBus: EventBus
  client?: Client
  goBackOnCancel?: boolean
}

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement<any, any>; }>(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LoginDialog = observer(class LoginDialog extends React.Component<Props> {
  showLoginDialog = false;
  private unauthorizedError?: UnauthorizedError;
  private authentication?: Authentication;
  private error?: string;
  private loading = false;
  private submitting = false;
  private consentChecked = false;
  private docUrl?: string;
  private formError?: string;
  private inputDay: string = '';
  private inputMonth: string = '';
  private inputYear: string = '';
  private inputText = '';
  private isMobile = false;

  constructor(props: Props) {
    super(props);

    makeObservable<LoginDialog, "unauthorizedError" | "authentication" | "error" | "loading" | "submitting" | "consentChecked" | "docUrl" | "formError" | "inputDay" | "inputMonth" | "inputYear" | "inputText" | "isMobile" | "inputDate" | "hasInput">(this, {
      showLoginDialog: observable,
      unauthorizedError: observable,
      authentication: observable,
      error: observable,
      loading: observable,
      submitting: observable,
      consentChecked: observable,
      docUrl: observable,
      formError: observable,
      inputDay: observable,
      inputMonth: observable,
      inputYear: observable,
      inputText: observable,
      isMobile: observable,
      inputDate: computed,
      hasInput: computed
    });
  }

  private get inputDate(): Moment | undefined {
    if (this.inputDay.length && this.inputMonth.length && this.inputYear.length === 4) {
      return moment(`${this.inputYear}-${_.padStart(this.inputMonth, 2, '0')}-${_.padStart(this.inputDay, 2, '0')}`, 'YYYY-MM-DD')
    }

    return undefined
  }

  private get hasInput(): boolean {
    const type = this.authentication?.factor.type

    if (type === 'date') {
      return !!(this.inputDate && this.inputDate.isValid())
    } else {
      return this.inputText.trim().length > 0
    }
  }

  componentDidMount (): void {
    this.props.eventBus.on('unauthorized-error', this.onUnauthorizedError)

    window.addEventListener('popstate', this.backListener)

    autorun(() => {
      if (this.showLoginDialog) {
        document.body.classList.add('has-backdrop')
      } else {
        document.body.classList.remove('has-backdrop')
      }
    })

    this.checkIsMobileWidth()
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.onUnauthorizedError)
    window.removeEventListener('popstate', this.backListener)
    window.removeEventListener('resize', this.onResize)
  }

  private backListener = () => {
    this.showLoginDialog = false
  }

  private onUnauthorizedError = (data: UnauthorizedErrorEvent) => {
    this.unauthorizedError = data.unauthorizedError
    this.showLoginDialog = true

    if (this.unauthorizedError) {
      this.loading = true
      ApiClientFactory.getInstance().get(getRelUrl(this.unauthorizedError.links, 'authentication'))
        .then(response => {
          this.authentication = new Authentication().init(response.data)
        })
        .catch(() => this.error = 'There was an error loading the authentication information')
        .then(() => this.loading = false)
    }
  }

  private handleLoginDialogClose = () => {
    this.showLoginDialog = false
    if (this.props.goBackOnCancel) {
      window.history.back()
    }
  }

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.authentication && !this.submitting && this.hasInput) {
      this.submitting = true
      this.formError = undefined

      const data = Object.assign({}, this.authentication.postback.postbackData)

      if (this.authentication.factor.type === 'date') {
        data[this.authentication.factor.id] = this.inputDate!.format('YYYY-MM-DD')
        data.consent = this.consentChecked
      } else if (this.authentication.factor.type === 'text' || this.authentication.factor.type === 'password') {
        data[this.authentication.factor.id] = this.inputText
        data.consent = this.consentChecked
      }

      ApiClientFactory.getInstance()
        .post(this.authentication.postback.postbackUrl, data)
        .then(response => {
          AppStateStore.persistAuthData({
            authToken: response.data.token,
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
            userShort: response.data.data
          })
          this.props.eventBus.dispatch('authenticated', { data: response.data.data })
          this.showLoginDialog = false
        })
        .catch(error => {
          this.formError = error?.response?.data?.error || error?.response?.data?.msg || 'Invalid login'
        })
        .then(() => this.submitting = false)
    }
  }

  private renderInput = (authentication: Authentication) => {
    if (authentication.factor.type === 'date') {
      return <DatePickerDropdown
        label={authentication.factor.label}
        month={this.inputMonth}
        day={this.inputDay}
        year={this.inputYear}
        onChange={(m, d, y) => {
          this.inputMonth = m
          this.inputDay = d
          this.inputYear = y
        }}
      />
    } else if (authentication.factor.type === 'text' || authentication.factor.type === 'password') {
      return <>
        <InputLabel htmlFor="authInput">{authentication.factor.label}</InputLabel>
        <Input
          fullWidth
          id="authInput"
          value={this.inputText || ''}
          onChange={ev => this.inputText = ev.target.value}
          disabled={this.submitting}
          type={authentication.factor.type}
        />
      </>
    } else {
      return null
    }
  }

  private renderConsentContent = (authentication: Authentication) => {
    return <>
      {authentication.agreement.businessText}
      <br/><br/>
      {authentication.agreement.documentText}
      {authentication.agreement.documents.map((doc, idx) => <span key={idx}>{idx > 1 ? ', ' : null} <Link
        onClick={this.handleAgreementDocLink}
        target="_blank"
        href={getRelUrl(authentication.links, doc.rel)}
        underline="hover">{doc.title}</Link></span>)}
    </>;
  }

  private handleAgreementDocLink = (ev: any) => {
    ev.preventDefault()
    this.docUrl = ev.target.href
  }

  private onResize = (ev: UIEvent) => {
    this.checkIsMobileWidth()
  }

  private checkIsMobileWidth = () => {
    this.isMobile = window.innerWidth < 1024
  }

  render () {
    const unauthorizedError = this.unauthorizedError
    const isMobile = this.isMobile
    const showLoginDialog = this.showLoginDialog

    return unauthorizedError
      ? <>
        <Dialog
          fullWidth={!isMobile}
          fullScreen={isMobile}
          maxWidth={!isMobile ? 'lg' : undefined}
          open={showLoginDialog}
          onClose={this.handleLoginDialogClose}
          TransitionComponent={Transition}
          TransitionProps={{
            onExited: () => {
              this.unauthorizedError = undefined
            }
          }}>
          <div className="hide-desktop">
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleLoginDialogClose}
                  aria-label="close"
                  size="large">
                  <CloseIcon/>
                </IconButton>
                <Typography variant="h6">
                  {unauthorizedError.header || 'Unauthorized'}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
          <DialogContent style={{ padding: 0 }}>
            <LoginDialogContent>
              <LoginDialogLeft className="hide-mobile">
                <div style={{ flex: 1 }}>
                  <LoginDialogLogo>
                    {
                      this.props.client?.logoSource
                        ? <img src={this.props.client.logoSource} alt=""/>
                        : null
                    }
                  </LoginDialogLogo>
                </div>
                <div className="login-dialog-close-button">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleLoginDialogClose}
                    fullWidth
                  >Cancel Login</Button>
                </div>
              </LoginDialogLeft>
              <LoginDialogRight>
                {
                  this.error
                    ? <div>{this.error}</div>
                    : (this.loading || !this.authentication)
                    ? <Loader/>
                    : <LoginForm>
                      <LoginHeader>
                        {this.authentication.header}
                      </LoginHeader>
                      <LoginFields method="post" onSubmit={this.onSubmit}>
                        <div>
                          {this.renderInput(this.authentication)}
                          {
                            this.formError
                              ? <FormHelperText error>{this.formError}</FormHelperText>
                              : null
                          }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Checkbox color="secondary" checked={this.consentChecked} onChange={c => this.consentChecked = c.target.checked} name="consent"/>
                          <div style={{ padding: 9 }}>{this.renderConsentContent(this.authentication)}</div>
                        </div>
                        <div className="login-button">
                          <Button
                            type="submit"
                            color="primary"
                            variant={this.authentication.postback.variant}
                            fullWidth
                            disabled={this.submitting || !this.hasInput || !this.consentChecked}
                          >
                            {this.authentication.postback.label}
                          </Button>
                        </div>
                      </LoginFields>
                    </LoginForm>
                }
              </LoginDialogRight>
            </LoginDialogContent>
          </DialogContent>
        </Dialog>
        {
          this.docUrl
            ? <GroupedValuesDialog
              url={this.docUrl}
              onExited={() => this.docUrl = undefined}
              maxWidth={!isMobile ? 'lg' : undefined}
            />
            : null
        }
      </>
      : null;
  }
});

export default LoginDialog
