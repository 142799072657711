import QuickAction from './QuickAction'
import Link from './Link'
import BaseModel from './BaseModel'
import { observable, makeObservable } from 'mobx';
import Client from './Client'
import { getRelUrl } from '../common/Util'
import ApiClientFactory from '../api/ApiClientFactory'

export class Assistant extends BaseModel {
  header: string
  quickaction?: QuickAction

  setQuickAction (a?: any) {
    return a ? new QuickAction().init(a) : undefined
  }
}

export class SigninFactor {
  label: string
  id: string
  type: string
  locale: string
}

export default class SigninData extends BaseModel {
  logoSource: string
  header: string
  body: string
  factor: SigninFactor
  postback: {
    label: string
    variant: 'text' | 'contained' | 'outlined'
    icon: string
    postbackUrl: string
    postbackData: any
    route: string
  }
  assistant?: Assistant
  instructor?: Assistant
  help: {
    label: string
    rel: string
  }
  policy: {
    label: string
    rel: string
  }
  term: {
    label: string
    rel: string
  }
  unsubscribe: {
    label: string
    rel: string
  }
  quickactions: QuickAction[]
  links: Link[]

  client?: Client;

  fetchClient = () => {
    const url = getRelUrl(this.links, 'client')
    if (url) {
      ApiClientFactory.getInstance()
        .get(url)
        .then(response => {
          this.client = response.data
        })
    }
  }

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      client: observable
    });
  }

  setAssistant (a?: []) {
    return a ? new Assistant().init(a) : undefined
  }

  setInstructor (a?: []) {
    return a ? new Assistant().init(a) : undefined
  }

  setLinks (a?: []) {
    return a ? a.map(aa => new Link().init(aa)) : []
  }

  setQuickactions (a?: []) {
    return a ? a.map(aa => new QuickAction().init(aa)) : []
  }
}
