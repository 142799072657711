import { styled } from '@mui/system';

export const CustomActionContainer = styled('div')``

export const CustomActionButton = styled('div')``

export const CustomActionList = styled('div')<{quad: boolean}>`
  ${props => props.theme.breakpoints.up('laptop')}{
    ${props => props.quad ?
        `& > div {
          &:nth-child(odd) {
            padding-right: 8px;
          }
    
          &:nth-child(even) {
            padding-left: 8px;
          }
    
          width: 50%;
          box-sizing: border-box;
          display: inline-block;
        }`: ''
    }
  }
  ${CustomActionContainer}{
    text-align: center;
    max-width: 600px;
    margin: auto;
  }
  ${CustomActionButton}{
    margin-top: 30px;
  }
`