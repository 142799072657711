import { observer } from "mobx-react"
import * as React from "react"
import { FormFieldType } from "./DFormField"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, TextField } from "@mui/material"
import DSelectAccountCard from "./DSelectAccountCard"
import { observable, toJS, makeObservable } from "mobx";
import _ from "lodash";
import Radio from "@mui/material/Radio"
import { StepperFormDescriptor } from './models/StepperFormDescriptor'
import DPaymentMethod from './DPaymentMethod'
import { getRelUrl } from '../../common/Util'
import Link from '../../models/Link'
import { NavigationListener } from './DStepper'
import DConfirmation from './DConfirmation'
import {DynamicForm, StepperCheckboxField} from "../../styles/DynamicForm.styles";

type Props = {
  links: Link[]
  form: StepperFormDescriptor
  onFormDataChanged: (formData: any) => void
  navigationListener: NavigationListener
}

const DForm = observer(class DForm extends React.Component<Props> {
  private formData: {[id: string]: any} = {};

  constructor(props: Props) {
    super(props);

    makeObservable<DForm, "formData">(this, {
      formData: observable
    });
  }

  componentDidMount (): void {
    this.formData = this.props.form.data
  }

  private onFieldValueChanged = (id: string, value: any) => {
    this.formData[id] = value

    this.props.onFormDataChanged(toJS(this.formData))
  }

  private onArrayDataChanged = (fieldId: string, optionId: string, selected: boolean) => {
    const data = _.find(this.formData[fieldId], d => d.id === optionId)

    if (data) {
      data.selected = selected
    }

    this.props.onFormDataChanged(toJS(this.formData));
  }

  render () {
    const form = this.props.form

    return (
      <DynamicForm>
        {
          form.description
            ? <p dangerouslySetInnerHTML={{ __html: form.description }}/>
            : null
        }
        {
          form.fields.map((field, idx) => {
            if (field.type === FormFieldType.TextField) {
              return (
                <FormGroup row key={idx}>
                  <TextField
                    variant="standard"
                    id={field.id}
                    label={field.title}
                    value={form.data[field.id]}
                    onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)}
                    fullWidth />
                </FormGroup>
              );
            } else if (field.type === FormFieldType.Checkbox) {
              return <StepperCheckboxField row key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox color="secondary"
                      checked={form.data[field.id]}
                      onChange={ev => this.onFieldValueChanged(field.id, ev.target.checked)}
                    />
                  }
                  label={field.title}
                />
              </StepperCheckboxField>
            } else if (field.type === FormFieldType.Array) {
              if (field.items.type === 'accountcard') {
                return form.data.accounts.map((data: any) => <div key={data.id} style={{ marginBottom: 10 }}>
                  <DSelectAccountCard
                    {...data}
                    onChange={selected => this.onArrayDataChanged(field.id, data.id, selected)}
                  />
                </div>)
              }
            } else if (field.type === FormFieldType.Date) {
              return (
                <FormGroup row key={idx}>
                  <TextField
                    variant="standard"
                    type="date"
                    id={field.id}
                    label={field.title}
                    value={form.data[field.id]}
                    onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </FormGroup>
              );
            } else if (field.type === FormFieldType.PhoneNumber) {
              return (
                <FormGroup row key={idx}>
                  <TextField
                    variant="standard"
                    type="tel"
                    id={field.id}
                    label={field.title}
                    value={form.data[field.id]}
                    onChange={ev => this.onFieldValueChanged(field.id, ev.target.value)} />
                </FormGroup>
              );
            } else if (field.type === FormFieldType.Radio) {
              return (
                <FormControl variant="standard" component="fieldset" key={idx}>
                  <FormLabel component="legend">{field.title}</FormLabel>
                  <RadioGroup
                    value={form.data[field.id]}
                    onChange={ev => this.onFieldValueChanged(field.id, (ev.target as HTMLInputElement).value)}
                  >
                    {
                      field.enum.map(value => <FormControlLabel value={value} control={<Radio color="secondary"/>} label={value}/>)
                    }
                  </RadioGroup>
                </FormControl>
              );
            } else if (field.type === FormFieldType.PaymentMethod) {
              return <DPaymentMethod
                key={idx}
                url={getRelUrl(this.props.links, field.rel)}
                navigationListener={this.props.navigationListener}
                onChange={tokenizedData => this.onFieldValueChanged(field.id, tokenizedData)}
              />
            } else if (field.type === FormFieldType.Confirmation) {
              return <DConfirmation key={idx} url={getRelUrl(this.props.links, field.rel)}/>
            }
            return null
          })
        }
      </DynamicForm>
    );
  }
});

export default DForm
