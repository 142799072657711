import {Button, Collapse, Typography} from '@mui/material'
import {ActionCard as ActionCardModel} from '../models/ActionCard'
import React from 'react'
import {observer} from 'mobx-react'
import {makeObservable, observable} from 'mobx';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {ActionDetail, Header3, Header4} from "../styles/App.styles";
import {CustomActionButton, CustomActionContainer} from "../styles/HomeView.styles";
import {
  DesktopAction,
  DesktopActionCard,
  DesktopActionCardContent,
  DesktopActionSeparator
} from "../styles/Desktop.styles";
import {
  ActionCardHeader,
  ActionCardHeaderText,
  ActionCardRowContainer,
  ActionCardRowValue,
  ActionCardRowValueDescription,
  ActionCardRowValueDetail,
  ActionCardRowValueDetailLabel,
  ActionCardRowValueDetailValue,
  ActionCardRowValueImage,
  ActionCardRowValueSublabel
} from "../styles/ActionCard.styles";

type Props = {
  actionCard: ActionCardModel
  onQuickActionClicked: (actionCard: ActionCardModel) => void
  collapseMobile?: boolean
}

export const ActionCard = observer(class ActionCard extends React.Component<Props> {
  private isMobile = false;
  private isExpanded = false;

  constructor(props: Props) {
    super(props);

    makeObservable<ActionCard, "isMobile" | "isExpanded">(this, {
      isMobile: observable,
      isExpanded: observable
    });
  }

  private shouldExpand (idx: number) {
    return idx < this.props.actionCard.displayRows || this.isExpanded
  }

  componentDidMount (): void {
    window.addEventListener('resize', this.onResize)

    this.checkIsMobileWidth()
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.onResize)
  }

  private onResize = (ev: UIEvent) => {
    this.checkIsMobileWidth()
  }

  private checkIsMobileWidth = () => {
    this.isMobile = window.innerWidth < 1024
  }

  private renderDesktopActionCard () {
    const actionCard = this.props.actionCard

    return <DesktopActionCard>
      <DesktopActionCardContent>
        {
          actionCard.header
            ? <ActionCardHeader noMargin={!this.isExpanded && this.props.actionCard.displayRows === 0}>
              <ActionCardHeaderText>{actionCard.header}</ActionCardHeaderText>
              {
                actionCard.displayRows < actionCard.rows.length
                  ? <Button onClick={() => this.isExpanded = !this.isExpanded}>{this.isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}</Button>
                  : null
              }
            </ActionCardHeader>
            : null
        }
        {
          actionCard.rows.map((row, idx) => <div key={idx} className="desktop-action-card-row">
            {
              row.type === 'Detail'
                ? <Collapse in={this.shouldExpand(idx)}>
                  <ActionCardRowContainer>
                    <ActionCardRowValue>
                      {
                        row.value.description
                          ? <ActionCardRowValueDescription><Typography color="primary">{row.value.description}</Typography></ActionCardRowValueDescription>
                          : null
                      }
                      {
                        row.value.subLabel
                          ? <ActionCardRowValueSublabel>{row.value.subLabel}</ActionCardRowValueSublabel>
                          : null
                      }
                    </ActionCardRowValue>
                    {
                      row.value.detail.label || row.value.detail.value || row.value.detail.imageSource
                        ? <ActionCardRowValueDetail>
                          {
                            row.value.detail.imageSource
                              ? <ActionCardRowValueImage src={row.value.detail.imageSource} alt=""/>
                              : null
                          }
                          {
                            row.value.detail.label
                              ? <ActionCardRowValueDetailLabel>{row.value.detail.label}</ActionCardRowValueDetailLabel>
                              : null
                          }
                          {
                            row.value.detail.value
                              ? <ActionCardRowValueDetailValue>{row.value.detail.value}</ActionCardRowValueDetailValue>
                              : null
                          }
                        </ActionCardRowValueDetail>
                        : null
                    }
                  </ActionCardRowContainer>
                </Collapse>
                : null
            }
            {
              row.type === 'Transaction'
                ? <Collapse in={this.shouldExpand(idx)}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      {row.value.date}
                    </div>
                    <div>
                      {row.value.amount}
                    </div>
                  </div>
                </Collapse>
                : null
            }
          </div>)
        }
        {
          actionCard.quickAction
            ? <>
              <DesktopActionSeparator/>
              <DesktopAction>
                <Button
                  color="primary"
                  variant={'text'}
                  fullWidth
                  onClick={() => this.props.onQuickActionClicked(actionCard)}
                >
                  {
                    // actionCard.quickAction.icon
                    //   ? <DynamicIcon icon={actionCard.quickAction.icon}/>
                    //   : null
                  }
                  {actionCard.quickAction.label}
                </Button>
              </DesktopAction>
            </>
            : null
        }
      </DesktopActionCardContent>
    </DesktopActionCard>
  }

  private renderMobileActionCard () {
    const action = this.props.actionCard

    return <CustomActionContainer style={{ marginBottom: 20 }}>
      {
        action.detail
          ? <ActionDetail>
            <Header3>{action.detail.label}</Header3>
            <Header4>{action.detail.value}</Header4>
          </ActionDetail>
          : null
      }
      {
        action.quickAction
          ? <CustomActionButton>
            <Button
              fullWidth
              onClick={() => this.props.onQuickActionClicked(action)}
              color="primary"
              variant={action.quickAction.variant}
            >
              {action.quickAction.label}
            </Button>
          </CustomActionButton>
          : null
      }
    </CustomActionContainer>
  }

  render () {
    return (this.props.collapseMobile && this.isMobile) ? this.renderMobileActionCard() : this.renderDesktopActionCard()
  }
});
