import EventBus from '../common/EventBus'
import { observer } from 'mobx-react'
import React from 'react'
import { observable, makeObservable } from 'mobx';
import ApiClientFactory from '../api/ApiClientFactory'
import UnauthorizedError from '../models/UnauthorizedError'
import { PaymentActivity } from '../models/PaymentActivity'
import Loader from './Loader'
import {
  PaymentActivityContainer,
  PaymentActivityHeader,
  PaymentActivityListItem,
  PaymentActivityMethod
} from "../styles/Payment.styles";

type Props = {
  url: string
  eventBus: EventBus
}

const PaymentActivityComponent = observer(class PaymentActivityComponent extends React.Component<Props> {
  private paymentActivity?: PaymentActivity;
  private error?: string;

  constructor(props: Props) {
    super(props);

    makeObservable<PaymentActivityComponent, "paymentActivity" | "error">(this, {
      paymentActivity: observable,
      error: observable
    });
  }

  componentDidMount (): void {
    this.props.eventBus.on('authenticated', this.onAuthenticated)

    this.fetchPaymentActivity()
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.onAuthenticated)
  }

  private onAuthenticated = () => {
    this.fetchPaymentActivity()
  }

  private fetchPaymentActivity = () => {
    this.paymentActivity = undefined
    this.error = undefined

    ApiClientFactory.getInstance()
      .get(this.props.url)
      .then(response => {
        this.paymentActivity = new PaymentActivity().init(response.data)
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          const unauthorizedError = new UnauthorizedError().init(error.response.data)
          this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
          this.error = unauthorizedError.friendlyMessage || unauthorizedError.header || 'Unauthorized'
        } else {
          this.error = 'There was an error loading your payment activity'
        }
      })
  }

  render () {
    return <PaymentActivityContainer>
      {
        this.error
          ? <div>{this.error}</div>
          : this.paymentActivity
          ? <>
            <PaymentActivityHeader>
              {this.paymentActivity.header}
            </PaymentActivityHeader>
            {
              !this.paymentActivity.current.length
                ? <div>{this.paymentActivity.defaultText}</div>
                : null
            }
            <div>
              {
                this.paymentActivity.current.map((item, idx) => <PaymentActivityListItem key={idx}>
                  <div style={{ flex: 1 }}>
                    <div>
                      {item.date.format('MMMM DD, YYYY')}
                    </div>
                    <PaymentActivityMethod>
                      {item.method}
                    </PaymentActivityMethod>
                  </div>
                  <div>
                    {item.amount}
                  </div>
                </PaymentActivityListItem>)
              }
            </div>
          </>
          : <Loader/>
      }
    </PaymentActivityContainer>
  }
});

export default PaymentActivityComponent
