export class Locale {
    code: string;
    display: string;
    muiLocaleText?: any;

    constructor(code: string, display: string, muiLocaleText?: any) {
        this.code = code;
        this.display = display;
        this.muiLocaleText = muiLocaleText;
    }
}