import React, {SyntheticEvent} from 'react'
import {ThemeProvider} from '@mui/material/styles';
import {observer} from 'mobx-react'
import {Route, RouteComponentProps, Switch} from 'react-router-dom'
import Config from '../common/Config'
import ApiClientFactory from '../api/ApiClientFactory'
import {autorun, computed, makeObservable, observable} from 'mobx';
import {
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Link as MuiLink,
  StyledEngineProvider,
  TextField,
  Theme,
} from '@mui/material';
import DialogProvider from '../components/dialog-provider/DialogProvider'
import {AppMenuRouter, AuthHeaderRouter} from '../App'
import AdapterLink from '../components/AdapterLink'
import {route} from '../routes/routes'
import {mapRouteNameToRoute, Routes} from '../routes/AppRoutes'
import {CommonContext} from '../CommonContext'
import {RootRouteContext} from '../RootRouteContext'
import NotFoundView from './NotFoundView'
import PolicyView from './PolicyView'
import HelpView from './HelpView'
import TermsView from './TermsView'
import SigninData, {Assistant, SigninFactor} from '../models/SigninData'
import {getRelUrl, joinUrls} from '../common/Util'
import Authentication from '../models/Authentication'
import LoginDialog from '../components/LoginDialog'
import EventBus from '../common/EventBus'
import UnauthorizedError from '../models/UnauthorizedError'
import {baseTheme} from "../base-theme";
import {
  AppClientLogo,
  AppContainer,
  AppContent,
  AppContentWrapper,
  AppFooter,
  AppLoaderContainer
} from "../styles/App.styles";
import {DesktopAuthHeader, DesktopClientLogo, DesktopHeader, DesktopLeftNav} from "../styles/Desktop.styles";
import {GenericLoginContainer} from "../styles/LoginForm.styles";
import {generateManifest, loadManifest, ManifestAppData} from "../common/manifest-generator";


declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}


const GenericLoginView = observer(class GenericLoginView extends React.Component<RouteComponentProps> {
  private authentication?: Authentication;
  private signinData?: SigninData;
  private eventBus = new EventBus()

  constructor(props: RouteComponentProps) {
    super(props);

    makeObservable<GenericLoginView, "authentication" | "signinData">(this, {
      authentication: observable,
      signinData: observable,
      theme: computed
    });
  }

  get theme() {
    const palette: any = {
      primary: { main: '#3c2d82' },
    }

    if (this.signinData?.client) {
      this.signinData.client.colors.forEach(color => {
        palette[color.type] = {
          main: color.code.hex
        }
      })
    }

    return createTheme({
      ...baseTheme,
      palette: palette,
    });
  }

  private getSigninData = async () => {
    const params: any = {}

    if (Config.API_SUB_ID) {
      params.sub = Config.API_SUB_ID
    }

    return ApiClientFactory.getInstance()
      .get(`https://e65d2wtk52.execute-api.us-east-1.amazonaws.com/dev/signin`, { params })
  }

  private setFavIcon (src: string | undefined) {
    let link: any = document.querySelector("link[rel~='icon']")

    if (!src && link) {
      link.remove()
    }

    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    link.href = src
  }

  componentDidMount () {
    autorun(() => {
      document.title = this.signinData?.client?.shortName || 'Patient App'
      const client = this.signinData?.client;
      this.setFavIcon(client?.faviconSource)
      //loadManifest(client as ManifestAppData)
    })

    this.getSigninData().then(response => {
      this.signinData = new SigninData().init(response.data)
      this.signinData.fetchClient()
    })

    this.eventBus.on('authenticated', this.onAuthenticated)
  }

  componentWillUnmount () {
    this.eventBus.remove(this.onAuthenticated)
  }

  private onAuthenticated = (ev: {data: string}) => {
    this.props.history.push(joinUrls([ev.data, Routes.home]))
  }

  private renderLoader = () => {
    return <AppLoaderContainer>
      <CircularProgress/>
    </AppLoaderContainer>
  }

  private renderBody = (signinData: SigninData) => {
    const client = this.signinData?.client

    return (
      <RootRouteContext.Consumer>{({ rootRoute }) =>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={this.theme}>
            <DialogProvider>
                <RootRouteContext.Provider value={{ rootRoute: '/' }}>
                  <CommonContext.Provider value={{ commonData: signinData }}>
                    <AppContainer>
                      <DesktopHeader className="hide-mobile">
                        {
                          client?.logoSource
                            ? <DesktopClientLogo>
                              <img src={client.logoSource} alt=""/>
                            </DesktopClientLogo>
                            : null
                        }
                        <DesktopAuthHeader>
                          <AuthHeaderRouter rootRoute="/"/>
                        </DesktopAuthHeader>
                      </DesktopHeader>
                      <div className="hide-desktop">
                        <AppMenuRouter
                          rootRoute="/"
                          position="top"
                          quickActions={signinData.quickactions}
                          theme={this.theme}
                        />
                      </div>
                      {
                        (client?.logoSource)
                          ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="hide-desktop">
                            <AppClientLogo>
                              <img src={client.logoSource} alt=""/>
                            </AppClientLogo>
                          </div>
                          : null
                      }
                      <AppContentWrapper>
                        <DesktopLeftNav>
                          <AppMenuRouter
                            rootRoute="/"
                            position="left"
                            quickActions={signinData.quickactions}
                            theme={this.theme}
                          />
                        </DesktopLeftNav>
                        <AppContent>
                          <Switch>
                            <Route
                              path="/"
                              component={() => <GenericLoginComponent signinData={signinData} eventBus={this.eventBus} rootRoute={rootRoute} history={this.props.history}/>}
                              exact={true}
                            />
                            <Route
                              path={Routes.policy}
                              component={PolicyView}
                              exact={true}
                            />
                            <Route
                              path={Routes.help}
                              component={HelpView}
                              exact={true}
                            />
                            <Route
                              path={Routes.terms}
                              component={TermsView}
                              exact={true}
                            />
                            <Route component={NotFoundView}/>
                          </Switch>
                        </AppContent>
                      </AppContentWrapper>
                      <div>
                        {this.renderFooter(signinData)}
                      </div>
                    </AppContainer>
                    {this.signinData ? <LoginDialog eventBus={this.eventBus} client={client}/> : null}
                  </CommonContext.Provider>
                </RootRouteContext.Provider>
            </DialogProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      }</RootRouteContext.Consumer>
    );
  }

  private renderFooter = (signinData: SigninData) => {
    return (
      <AppFooter>
        <MuiLink
          color="inherit"
          component={AdapterLink}
          to={route(Routes.policy)}
          underline="hover">{signinData.policy.label}</MuiLink>
        &nbsp;
        &nbsp;
        &nbsp;
        <MuiLink
          color="inherit"
          component={AdapterLink}
          to={route(Routes.terms)}
          underline="hover">{signinData.term.label}</MuiLink>
      </AppFooter>
    );
  }

  render () {
    return this.signinData && this.signinData.client
      ? this.renderBody(this.signinData)
      : this.renderLoader()
  }
});

type GenericLoginComponentProps = {
  signinData: SigninData
  eventBus: EventBus
  rootRoute: string
} & Partial<RouteComponentProps>

const GenericLoginComponent = observer(
  class GenericLoginComponent extends React.Component<GenericLoginComponentProps> {
    formError?: string;
    submitting = false;
    inputValue = '';
    renderInstructorDialog = false;
    renderAssistantDialog = false;
    isMobile = false;

    private onResize = (ev: UIEvent) => {
      this.checkIsMobileWidth()
    }

    private checkIsMobileWidth = () => {
      this.isMobile = window.innerWidth < 1024
    }

    constructor(props: GenericLoginComponentProps) {
      super(props);

      makeObservable(this, {
        formError: observable,
        submitting: observable,
        inputValue: observable,
        renderInstructorDialog: observable,
        renderAssistantDialog: observable,
        isMobile: observable
      });
    }

    componentDidMount () {
      this.checkIsMobileWidth()
      window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.onResize)
    }

    private onSubmit = (ev: SyntheticEvent) => {
      ev.preventDefault()

      if (this.inputValue && !this.submitting) {
        this.submitting = true
        this.formError = undefined

        const data = Object.assign({}, this.props.signinData.postback.postbackData)

        if (this.props.signinData.factor.type === 'text') {
          data[this.props.signinData.factor.id] = this.inputValue
        }

        ApiClientFactory.getInstance()
          .post(this.props.signinData.postback.postbackUrl, data)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            if (error.response?.status === 401) {
              const unauthorizedError = new UnauthorizedError().init(error.response.data)
              this.props.eventBus.dispatch('unauthorized-error', { unauthorizedError })
            } else {
              this.formError = error.response?.data?.msg || (error.response?.status === 400 ? 'Invalid login' : 'Server error')
            }
          })
          .then(() => this.submitting = false)
      }
    }

    private renderInput = (factor: SigninFactor, error?: string) => {
      if (factor.type === 'text') {
        return <TextField
          error={!!error}
          fullWidth
          type="text"
          label={factor.label}
          variant="outlined"
          margin="none"
          value={this.inputValue}
          onChange={ev => this.inputValue = ev.target.value}
          disabled={this.submitting}
          helperText={error}
        />
      } else {
        return null
      }
    }

    private navigateAssistant = (assistant: Assistant) => {

    }

    render () {
      return (
        <GenericLoginContainer>
          {
            !this.isMobile
              ? <div style={{ textAlign: 'center' }}>
                {
                  this.props.signinData.client?.logoSource
                    ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                      <AppClientLogo noMargin><img src={this.props.signinData.client.logoSource} alt=""/></AppClientLogo>
                    </div>
                    : null
                }
              </div>
              : null
          }
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            <div style={{ fontSize: 28, color: '#333' }}>{this.props.signinData.header}</div>
            <div style={{ fontSize: 21, color: '#333', marginTop: 10 }}>{this.props.signinData.body}</div>
          </div>
          <div>
            <form method="post" onSubmit={this.onSubmit}>
              <div style={{ marginTop: 10, marginBottom: 60 }}>
                <div>
                  {this.renderInput(this.props.signinData.factor, this.formError)}
                </div>
                {
                  this.props.signinData.assistant?.quickaction
                    ? <div style={{ marginTop: 10 }}>
                      {
                        this.props.signinData.assistant?.header
                          ? <p style={{ marginBottom: 4 }}>{this.props.signinData.assistant.header}</p>
                          : null
                      }
                      <Link
                        color="primary"
                        href="#"
                        style={{ fontWeight: 500 }}
                        onClick={(ev: SyntheticEvent) => {
                          ev.preventDefault()
                          this.props.signinData.assistant?.quickaction?.route
                            ? this.props.history!.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(this.props.signinData.assistant?.quickaction.route)]), {}, { rel: this.props.signinData.assistant?.quickaction.rel }))
                            : this.renderAssistantDialog = true
                        }}
                        underline="hover">{this.props.signinData.assistant.quickaction.label}</Link>
                    </div>
                    : null
                }
                {
                  this.props.signinData.instructor?.quickaction
                    ? <div style={{ marginTop: 10 }}>
                      {
                        this.props.signinData.instructor?.header
                          ? <p style={{ marginBottom: 4 }}>{this.props.signinData.instructor.header}</p>
                          : null
                      }
                      <Link
                        color="primary"
                        href="#"
                        style={{ fontWeight: 500 }}
                        onClick={(ev: SyntheticEvent) => {
                          ev.preventDefault()
                          this.props.signinData.instructor?.quickaction?.route
                            ? this.props.history!.push(route(joinUrls([this.props.rootRoute, mapRouteNameToRoute(this.props.signinData.instructor?.quickaction.route)]), {}, { rel: this.props.signinData.instructor?.quickaction.rel }))
                            : this.renderInstructorDialog = true
                        }}
                        underline="hover">{this.props.signinData.instructor.quickaction.label}</Link>
                    </div>
                    : null
                }
              </div>
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant={this.props.signinData.postback.variant}
                  disabled={this.submitting || !this.inputValue}
                  fullWidth={this.isMobile}
                >
                  {this.props.signinData.postback.label}
                </Button>
              </div>
            </form>
            {
              this.renderAssistantDialog
                ? <Dialog
                  onClose={() => this.renderAssistantDialog = false}
                  aria-labelledby="simple-dialog-title"
                  open={true}
                  fullWidth={true}
                  maxWidth='lg'
                >
                  <DialogTitle id="simple-dialog-title">
                    <Button fullWidth onClick={() => this.renderAssistantDialog = false}>Close Window</Button>
                  </DialogTitle>
                  <DialogContent>
                    {
                      this.props.signinData.assistant?.quickaction
                        ? <img src={getRelUrl(this.props.signinData.links, this.props.signinData.assistant.quickaction.rel)} alt=""/>
                        : null
                    }
                  </DialogContent>
                </Dialog>
                : null
            }
            {
              this.renderInstructorDialog
                ? <Dialog
                  onClose={() => this.renderInstructorDialog = false}
                  aria-labelledby="simple-dialog-title"
                  open={true}
                  fullWidth={true}
                  maxWidth='lg'
                >
                  <DialogTitle id="simple-dialog-title">
                    <Button fullWidth onClick={() => this.renderInstructorDialog = false}>Close Window</Button>
                  </DialogTitle>
                  <DialogContent>
                    {
                      this.props.signinData.instructor?.quickaction
                        ? <img src={getRelUrl(this.props.signinData.links, this.props.signinData.instructor.quickaction.rel)} alt=""/>
                        : null
                    }
                  </DialogContent>
                </Dialog>
                : null
            }
          </div>
        </GenericLoginContainer>
      );
    }
  }
);

export default GenericLoginView
