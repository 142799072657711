import React from 'react'
import { InteractionContext } from '../InteractionContext'
import { observer } from 'mobx-react'
import { joinUrls, transformIf } from '../common/Util'
import EventBus, { EventBusContext } from '../common/EventBus'
import { Tab, Tabs } from '@mui/material'
import AppStateStore from '../stores/AppStateStore'
import queryString from 'query-string'
import { RouteComponentProps } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import Interaction from '../models/Interaction'
import { RootRouteContext } from '../RootRouteContext'
import ProfileComponent from '../components/ProfileComponent'
import PaymentActivityComponent from '../components/PaymentActivityComponent'
import PaymentPlansComponent from '../components/PaymentPlansComponent'
import {ProfileTabsContainer} from "../styles/Profile.styles";

type ViewProps = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

const ProfileView = observer(class ProfileView extends React.Component<ViewProps> {
  render () {
    const tab = this.props.match.params.tab || TABS.profile

    return <RootRouteContext.Consumer>{({ rootRoute }) => <EventBusContext.Consumer>
      {eventBusContext => <InteractionContext.Consumer>
        {interactionContext => <ProfileViewTabsComponent
          interaction={interactionContext.interaction}
          tab={tab}
          eventBus={eventBusContext.eventBus}
          rootRoute={rootRoute}
          history={this.props.history}
        />}
      </InteractionContext.Consumer>}
    </EventBusContext.Consumer>
    }</RootRouteContext.Consumer>
  }
});

type ProfileViewTabsProps = {
  interaction: Interaction
  tab?: string
  eventBus: EventBus
  rootRoute: string
  history: any
}

export const TABS = {
  profile: 'profile',
  paymentActivity: 'payment-activity',
  paymentPlans: 'payment-plans',
}

const ProfileViewTabsComponent = observer(
  class ProfileViewTabsComponent extends React.Component<ProfileViewTabsProps> {
    componentDidMount (): void {
      this.setActiveView()
    }

    private setActiveView = () => {
      if (this.props.tab === TABS.profile) {
        AppStateStore.activeView = 'Profile'
      } else if (this.props.tab === TABS.paymentActivity) {
        AppStateStore.activeView = 'PaymentActivity'
      } else if (this.props.tab === TABS.paymentPlans) {
        AppStateStore.activeView = 'PaymentPlan'
      }
    }

    private setTab (tab: string) {
      if (tab === TABS.profile) {
        this.props.history.push(joinUrls([this.props.rootRoute, route(Routes.profile, { tab: undefined })]))
      } else {
        this.props.history.push(joinUrls([this.props.rootRoute, route(Routes.profile, { tab })]))
      }
      this.setActiveView()
    }

    private handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      this.setTab(newValue)
    }

    render () {
      const activeTab = this.props.tab

      return activeTab
        ? <>
          <ProfileTabsContainer>
            <Tabs indicatorColor="secondary" textColor="inherit" value={activeTab} onChange={this.handleTabChange} variant="fullWidth">
              <Tab label="Profile" value={TABS.profile}/>
              <Tab label="Payment Activity" value={TABS.paymentActivity}/>
              <Tab label="Payment Plans" value={TABS.paymentPlans}/>
            </Tabs>
          </ProfileTabsContainer>
          {
            activeTab === TABS.profile
              ? transformIf(this.props.interaction.getQuickActionLinkByRel(transformIf(queryString.parse(window.location.search).rel, t => String(t)) ?? 'profile'), link => <ProfileComponent
                url={link.href}
                eventBus={this.props.eventBus}
              />)
              : null
          }
          {
            activeTab === TABS.paymentActivity
              ? transformIf(this.props.interaction.getQuickActionLinkByRel(transformIf(queryString.parse(window.location.search).rel, t => String(t)) ?? 'paymentactivity'), link => <PaymentActivityComponent
                url={link.href}
                eventBus={this.props.eventBus}
              />)
              : null
          }
          {
            activeTab === TABS.paymentPlans
              ? transformIf(this.props.interaction.getQuickActionLinkByRel(transformIf(queryString.parse(window.location.search).rel, t => String(t)) ?? 'paymentplans'), link => <PaymentPlansComponent
                url={link.href}
                eventBus={this.props.eventBus}
                rootRoute={this.props.rootRoute}
              />)
              : null
          }
        </>
        : null
    }
  }
);

export default ProfileView
