import React from 'react'
import { MenuItem, Select, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import _ from 'lodash'
import {WithTranslation, withTranslation} from "react-i18next";

type Props = {
  label?: string
  month: string
  day: string
  year: string
  onChange: (month: string, day: string, year: string) => void
  disabled?: boolean
} & WithTranslation

const DatePickerDropdown = withTranslation()(observer(class DatePickerDropdown extends React.Component<Props> {
  render () {
    const {t} = this.props
    return (
      <div>
        {this.props.label ? <label>{this.props.label}</label> : null}
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: 10, width: 120 }}>
            <Select
              variant="standard"
              label="Month"
              value={this.props.month}
              onChange={d => this.props.onChange(String(d.target.value), this.props.day, this.props.year)}
              fullWidth
              displayEmpty={true}>
              <MenuItem value="" disabled>{t("dates.monthFormat")}</MenuItem>
              <MenuItem value="1">{t("dates.jan")}</MenuItem>
              <MenuItem value="2">{t("dates.feb")}</MenuItem>
              <MenuItem value="3">{t("dates.mar")}</MenuItem>
              <MenuItem value="4">{t("dates.apr")}</MenuItem>
              <MenuItem value="5">{t("dates.may")}</MenuItem>
              <MenuItem value="6">{t("dates.jun")}</MenuItem>
              <MenuItem value="7">{t("dates.jul")}</MenuItem>
              <MenuItem value="8">{t("dates.aug")}</MenuItem>
              <MenuItem value="9">{t("dates.sep")}</MenuItem>
              <MenuItem value="10">{t("dates.oct")}</MenuItem>
              <MenuItem value="11">{t("dates.nov")}</MenuItem>
              <MenuItem value="12">{t("dates.dec")}</MenuItem>
            </Select>
          </div>
          <div style={{ width: 60, paddingRight: 10 }}>
            <Select
              variant="standard"
              label="Day"
              value={this.props.day}
              onChange={d => this.props.onChange(this.props.month, String(d.target.value), this.props.year)}
              fullWidth
              displayEmpty={true}>
              <MenuItem value="" disabled>DD</MenuItem>
              {
                _.range(1, 32).map(day => <MenuItem key={day} value={String(day)}>{day}</MenuItem>)
              }
            </Select>
          </div>
          <div style={{ width: 80 }}>
            <div>
              <TextField
                variant="standard"
                type="number"
                value={this.props.year}
                placeholder="YYYY"
                onChange={d => this.props.onChange(this.props.month, this.props.day, String(d.target.value))} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}));

export default DatePickerDropdown
