import * as React from "react"
import DSummaryRow from "./DSummaryRow"
import { observer } from "mobx-react"
import { StepperSummarySectionDescriptor } from './models/StepperSummarySectionDescriptor'
import { Card, CardContent, Theme } from '@mui/material'
import { withTheme } from '@mui/styles'

type Props = {
  summarySection: StepperSummarySectionDescriptor
  theme: Theme
}

const DSummarySection = observer(class DSummarySection extends React.Component<Props> {
  render () {
    return <div className="stepper-summary-section">
      {
        this.props.summarySection.header
          ? <h4>{this.props.summarySection.header}</h4>
          : null
      }
      <Card variant="outlined">
        <CardContent style={{ padding: 12 }}>
          <div style={{ color: this.props.theme.palette.primary.main }}>
            {this.props.summarySection.rows.map((row, idx) => <DSummaryRow key={idx} summaryRow={row}/>)}
          </div>
        </CardContent>
      </Card>
    </div>
  }
});

export default withTheme<Theme, typeof DSummarySection>(DSummarySection) //TODO remove withTheme in favor of useTheme and simplify Types