import {styled} from '@mui/system';
import {Button} from "@mui/material";
import {Header3, Header4} from "./App.styles";

export const PaymentFormContent = styled('div')`
  max-width: 600px;
  padding: 0 24px;
  margin: auto;

  ${props => props.theme.breakpoints.up('laptop')} {
    border: solid 1px #999;
    border-radius: 12px;
    box-shadow: 2px 4px 4px 0 #ddd;
    padding: 20px;
    max-width: 700px;
    margin-top: 50px;
  }
`;

export const BalanceHeader = styled(Header3)`
  text-align: center;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: bold;
  margin: 0;
`;

export const BalanceDue = styled(Header4)`
  text-align: center;
  margin: 0;
  margin-top: 4px;
  color: #c00;
  font-size: 16pt;
`;

export const PaymentFields = styled('form')`
  margin-top: 40px;

  > div {
    margin-bottom: 40px;

    label {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PaymentConfirmationStatusMessage = styled('div')`
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
`;

export const PaymentConfirmationMessage = styled('div')`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11pt;
`;

export const PaymentConfirmationGroup = styled('div')`
  display: flex;
  margin-bottom: 6px;
  font-size: 11pt;
`;

export const PaymentConfirmationLabel = styled('div')`
  flex: 1;
  padding-right: 10px;
`;

export const PaymentConfirmationValue = styled('div')`
  text-align: right;
`;

export const PaymentFieldsWrapper = styled('div')`
  > div {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .MuiButton-root > div {
    width: 100%;
  }
  

  ${props => props.theme.breakpoints.up('laptop')} {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
      padding: 30px 50px;
    }
  }
`;

export const PaymentOptionButton = styled(Button)`
  font-size: 12px !important;
  margin-bottom: 10px !important;
  font-weight: normal !important;
  line-height: 14px !important;
  text-transform: none !important;

  & .MuiButton-root > div {
    width: 100%;
  }
`;

export const PaymentButton = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    text-align: right;
  }
`;

export const DesktopPaymentHeader = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    display: flex;
    flex-direction: row;
    font-weight: bold;
  }
`;

export const DesktopPaymentHeaderTitle = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    flex: 1;
  }
`;

export const DesktopPaymentHeaderBalance = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    flex: 1;
    text-align: right;
  }
`;

export const PaymentFormContainer = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')} {
    padding: 0 40px;
  }
`;

export const PaymentActivityContainer = styled('div')`
  max-width: 700px;
  margin: 0 auto;

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 0 20px;
  }
`;

export const PaymentActivityHeader = styled('div')`
  font-weight: bold;
  padding: 10px 0;
`;

export const PaymentActivityMethod = styled('div')`
  font-size: 12px;
  color: #666;
`;

export const PaymentPlansTabContainer = styled('div')`
  margin-bottom: 10px;
`

export const PaymentActivityListItem = styled('div')`
  &:last-child {
    border-bottom: solid 1px #ddd;
  }

  display: flex;
  flex-direction: row;
  border-top: solid 1px #ddd;
  padding: 20px 40px;

  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 20px 0 !important;
  }
`