import {DatePicker, DatePickerToolbar} from '@mui/x-date-pickers/DatePicker';
import React from "react";
import {styled} from '@mui/system';
import {getTextColor} from "../common/Util";
import {DateField} from "@mui/x-date-pickers";
import {DateFieldProps} from "@mui/x-date-pickers/DateField/DateField.types";
import {useTranslation} from "react-i18next";

const ColouredToolbar = styled(DatePickerToolbar)`
      background: ${props => props.theme.palette.primary.main};
      color: ${props => getTextColor(props.theme.palette.primary.main)};;
      & .MuiTypography-root {
        color: ${props => getTextColor(props.theme.palette.primary.main)};;
      }
`

function FormattedDateField<TDate>(props: DateFieldProps<TDate>){
    const { t } = useTranslation();
    return <DateField {...props} format={t("dates.dateFormat")}/>
}

const FullWidthDateField = styled(FormattedDateField)`
  ${props => props.theme.breakpoints.down('laptop')}{
    width: 100%;
  }
`

export const StyledDatePicker = ({...props}) => {
    return <DatePicker
        {...props}
        slotProps={{textField: {variant: "standard"}}}
        slots ={{
            toolbar: ColouredToolbar,
            field: FullWidthDateField,
        }}
    />
}