import React from "react"
import Interaction from './models/Interaction'
import Link from './models/Link'

type CommonData = {
  help: {
    label: string
    rel: string
  }
  policy: {
    label: string
    rel: string
  }
  term: {
    label: string
    rel: string
  }
  unsubscribe: {
    label: string
    rel: string
  }
  links: Link[]
}

type CommonContextType = {
  commonData: CommonData
}

const defaultState: CommonContextType = {
  commonData: new Interaction()
}

export const CommonContext = React.createContext(defaultState)
