import { styled } from '@mui/system';
import {BottomNavigation} from "@mui/material";

export const AppFooter = styled('div')`
  text-align: center;
  background-color: #f7f7f7;
  padding: 15px 0;
  font-size: 11pt;
  position: fixed;
  bottom: 0;
  left: 0;
  ${props => props.theme.breakpoints.up('laptop')}{
    text-align: left;
    background-color: #fff;
    padding: 30px;
  }
  ${props => props.theme.breakpoints.down('laptop')}{
    width: 100%;
  }
`;

export const AppContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  select {
    font-size: 12pt;

    option:disabled {
      color: #999;
    }
  }
`;

export const AppContent = styled('div')`
  flex: 1;
  padding-bottom: 70px;
`;

export const MobileBottomNav = styled(BottomNavigation)`
  margin-top: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
`

export const AuthNavBar = styled('div')`
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  font-size: 10pt;
  ${props => props.theme.breakpoints.up('laptop')}{
    width: auto;
    max-width: none;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    height: 100%;
    font-size: 12pt;
  }
`

export const AppLoaderContainer = styled('div')`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`

export const AppClientLogo = styled('div')<{noMargin?: boolean}>`
  width: 250px;
  height: 80px;
  text-align: center;
  margin-bottom: ${props => props.noMargin ? 0 : `30px` };
  margin-top: 30px;
  img {
    width: 250px;
    height: 38px;
  }
`

export const HomeGreeting = styled('div')`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  ${props => props.theme.breakpoints.up('laptop')}{
    font-size: 24pt;
  }
`

export const HomeText = styled('div')`
  margin-bottom: 20px;
  text-align: center;
`

export const ActionDetail = styled('div')`
  text-align: center;
  h3 {
    text-transform: uppercase;
    font-size: 10pt;
    font-weight: bold;
    margin: 0;
  }
  h4 {
    margin: 0;
    margin-top: 4px;
    color: #c00;
    font-size: 16pt;
  }
`

export const AppContentWrapper = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const HeaderHelpLink = styled('div')`
  display: flex;
  ${props => props.theme.breakpoints.up('laptop')} {
    justify-content: flex-end;
  }
  ${props => props.theme.breakpoints.down('laptop')}{
    flex: 1;
  }
`
//TODO check whether the !important's are necessary
export const Header3 = styled('h3')`
  font-size: 11pt !important;
  font-weight: 500 !important;
`

export const Header4 = styled('h4')`
  font-size: 17pt !important;
  font-weight: 500 !important;
`

export const LowerAuthBar = styled('div')`
  display: flex;
  justify-content: flex-end;
`