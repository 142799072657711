import {styled} from '@mui/system';

export const ProfileViewContainer = styled('div')`
  padding: 0 24px;
  ${props => props.theme.breakpoints.up('laptop')}{
    padding: 0 40px;
  }
`;

export const ProfileSectionHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  ${props => props.theme.breakpoints.down('laptop')}{
    justify-content: center;
  }
`

export const ProfileSectionHeaderText = styled('div')`
  ${props => props.theme.breakpoints.up('laptop')}{
    flex: 1;
    font-weight: 600;
  }
`

export const ProfileTabsContainer = styled('div')`
  max-width: 700px;
  margin: 0 auto 20px;
`